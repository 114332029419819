import { Box, Heading } from "grommet";
import React, { ReactElement } from "react";
import { BannedWrapper } from "./styles";

export const Banned = (): ReactElement => {
  return (
    <Box>
      <BannedWrapper>
        <Heading level={2}>
          Your account has been banned from RetirementGG
        </Heading>

        <Heading level={4}>
          Looks like you have been banned from RetirementGG!
        </Heading>
        {/* <Box direction="row">
          <Heading level={4}>Please reach out to our Discord server </Heading>
          
          <SocialIcon
            icon={IconName.discord}
            href={SocialURLS.DISCORD_INVITE}
            size={30}
            link
          />
        </Box> */}
      </BannedWrapper>
    </Box>
  );
};
