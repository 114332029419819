import styled, { css } from "styled-components";
import { ButtonProps, ButtonProviderProps, Provider } from "./types";
import { theme } from "../../Styled";

const socialColorMap = {
  twitch: {
    base: theme.colors.twitchBase,
    hover: theme.colors.twitchHighlight,
    color: "#fff",
  },
  google: {
    base: "#ff3d00",
    hover: "#cc3100",
    color: "#fff",
  },
  discord: {
    base: "#7289da",
    hover: "#5b6dae",
    color: "#fff",
  },
  streamlabs: {
    base: theme.colors.streamlabsBase,
    hover: theme.colors.streamlabsHighlight,
    color: "#000",
  },
};

const hoverEffectStyles = css`
  :after {
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(0, 0, 0, 0.15)
    );
    border-radius: ${theme.borders.radius};
    content: "";
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  :hover {
    :after {
      opacity: 1;
    }
  }
`;

const buttonDefaultStyles = css`
  border-radius: ${theme.borders.radius};
  border: 1px solid;
  color: ${theme.colors.baseWhite};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 600;
  position: relative;
  transition: 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);

  :disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const StyledButton = styled.button<ButtonProps>`
  ${buttonDefaultStyles}

  ${({ size }) => {
    if (size === "sm") {
      return css`
        padding: 0.9rem ${theme.space.lg};
      `;
    } else if (size === "icon") {
      return css`
        padding: 0.5rem;
      `;
    } else {
      return css`
        height: 42px;
        padding: ${theme.space.sm} ${theme.space.lg};
      `;
    }
  }}

  ${({ color }) => {
    if (color === "primary") {
      return css`
        background-color: ${theme.colors.primary};
        border-color: ${theme.colors.primary};

        ${hoverEffectStyles}
      `;
    }

    if (color === "secondary") {
      return css`
        background: ${theme.colors.secondary};
        border: 0;

        ${hoverEffectStyles}

        // &:after {
        //   content: "";
        //   background: linear-gradient(
        //     to bottom right,
        //     rgba(214, 6, 123, 1),
        //     rgba(9, 88, 110, 1)
        //   );
        //   border: inherit;
        //   border-radius: inherit;
        //   position: absolute;
        //   top: 0;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   opacity: 0;
        //   transition: opacity 0.2s ease-in-out;
        // }

        // &:hover:after {
        //   opacity: 1;
        // }

        > span {
          position: relative;
          z-index: 2;
        }
      `;
    }

    if (color === "none") {
      return css`
        background-color: transparent;
        border: 0px solid;

        :hover {
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};
          svg {
            fill: ${theme.colors.black};
            stroke: ${theme.colors.black};
          }
        }
      `;
    }

    if (color === "outline") {
      return css`
        background-color: transparent;
        border-color: ${theme.colors.white};

        :hover {
          background-color: ${theme.colors.white};
          color: ${theme.colors.black};
          svg {
            fill: ${theme.colors.black};
            stroke: ${theme.colors.black};
          }
        }
      `;
    }

    if (color === "danger") {
      return css`
        background-color: ${theme.colors.danger};
        border-color: ${theme.colors.danger};

        ${hoverEffectStyles}
      `;
    }

    return css`
      background-color: ${color};
      border-color: ${color};

      ${hoverEffectStyles}
    `;
  }}

  &:has(img) {
    padding: 0.5rem 1rem;
  }
`;

export const StyledProviderButton = styled.button<ButtonProviderProps>`
  ${buttonDefaultStyles}

  ${({ provider, last }) => {
    return css`
      /* color: #fff; */
      color: ${socialColorMap[
        Provider[provider].toLowerCase() as keyof typeof socialColorMap
      ].color};
      text-decoration: none;
      display: block;
      width: 100%;
      max-width: 271px;
      margin-bottom: ${last ? "0" : "15px"};
      background: ${socialColorMap[
        Provider[provider].toLowerCase() as keyof typeof socialColorMap
      ].base};
      border-color: ${socialColorMap[
        Provider[provider].toLowerCase() as keyof typeof socialColorMap
      ].base};
      font-size: 17px;
      padding: 9.5px 20px;
      height: 42px;

      &:hover {
        background: ${socialColorMap[
          Provider[provider].toLowerCase() as keyof typeof socialColorMap
        ].hover};
        border-color: ${socialColorMap[
          Provider[provider].toLowerCase() as keyof typeof socialColorMap
        ].hover};
      }
    `;
  }}
`;
