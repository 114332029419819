import styled from "styled-components";
import { gradients, theme } from "../Styled";
import { ToastContainer, ToastOptions } from "react-toastify";
import { toast } from "react-toastify";

export const StyledToastContainer = styled(ToastContainer).attrs({
  // custom props
})`
  &.Toastify__toast-container--bottom-center {
    text-align: center;
    bottom: 90px !important;
  }

  .Toastify__toast {
    background: ${theme.colors.black};
    font-family: ${theme.font.familyPrimary};
    font-size: 1rem;
    min-height: 70px;
    width: 300px;
  }

  .remove-playlist-toast .Toastify__toast-icon {
    margin-inline-end: 1rem;
  }
`;

export const StyledToastContainerOverlay = styled(ToastContainer).attrs({
  // custom props
})`
  &.Toastify__toast-container--bottom-center {
    text-align: center;
    bottom: 90px !important;
  }

  .Toastify__toast {
    background: ${gradients.brand};
    font-family: ${theme.font.twitchChat};
    font-size: 1rem;
    min-height: 70px;
    width: 300px;
  }

  .Toastify__toast-icon {
    width: 40px;
    margin-inline-end: 1.563rem;
  }
  .remove-playlist-toast .Toastify__toast-icon {
    margin-inline-end: 1rem;
  }
  .Toastify__progress-bar {
    background: ${theme.colors.primary};
  }
`;

export const DividerContainer = styled.div`
  height: 60px;
  margin: -0.5rem;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1.875rem;
`;

export const TrashIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
`;

export const StyledDivider = styled.div`
  width: 5px;
  height: 100%;
  background-color: ${gradients.brand};
  border-radius: 1.25rem;
`;

const baseConfig = {
  position: toast.POSITION.TOP_RIGHT,
  style: { top: "0px" },
};

export const generateToastConfig = (
  type?: string,
  customConfig?: ToastOptions
) => {
  const config: ToastOptions = customConfig ? customConfig : baseConfig;
  return {
    ...config,
    icon: (
      <>
        <DividerContainer>
          <StyledDivider />
        </DividerContainer>
        {/* <IconContainer>
          {type === "Playlist" ? (
            <Icon size={24} name={type} />
          ) : (
            <Icon name={type} noFill svgColour="white" />
          )}
        </IconContainer> */}
      </>
    ),
  };
};
