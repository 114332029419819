import { FC } from "react";
import { OverlayMessageHandler } from "../../core/Message";
import { WebSocketProvider } from "../../core/WebSocketContext/WebSocketProvider";

export const OverlayPage: FC<{}> = () => {
  const urlParam = window.location.pathname.split("/")[2];

  return (
    <WebSocketProvider overlayChannel={urlParam}>
      <OverlayMessageHandler />
    </WebSocketProvider>
  );
};
