import React, { FC } from "react";

import { Box, BoxProps, PageContent, Spinner as GrommetSpinner } from "grommet";
import { theme } from "../../Styled";

interface SpinnerProps {
  background?: string;
}

export const Spinner: FC<
  SpinnerProps & BoxProps & React.HTMLAttributes<HTMLDivElement>
> = ({ background }) => (
  <PageContent>
    <Box direction="row" pad="small" gap="small">
      <Box
        round="small"
        pad="large"
        direction="column"
        background={background ? background : theme.colors.bodyBg}
        fill
      >
        <Box align="center">
          <GrommetSpinner size="medium" />
        </Box>
      </Box>
    </Box>
  </PageContent>
);
