import styled from "styled-components";
import { theme } from "../../Styled";

export const StyledHeading = styled.h1`
  color: ${theme.colors.baseWhite};
  font-size: min(calc(2vw + 2vh), ${theme.headings.h1.fontSize});
`;

export const StyledPageIntro = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 1.45rem 0 2rem;

  h1 {
    // Slowly add font family to headings globally
    font-family: ${theme.font.familyPrimary};
    margin-bottom: 0.5rem;
  }

  p {
    font-size: 1.25rem;
    color: ${theme.colors.baseWhite};
    margin-top: ${theme.space.sm};
    margin-bottom: 0;
  }
`;

export const StyledSpacer = styled.div`
  flex: 1;
`;

export const StyledHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${theme.mediaQueries.medium} {
    align-items: center;
    flex-direction: row;
  }

  h1 {
    margin-bottom: ${theme.space.lg};
    margin-right: 4rem;

    ${theme.mediaQueries.medium} {
      margin-bottom: 0;
    }
  }

  a {
    color: ${theme.colors.playstationGreyLight} !important;
    display: inline-block;
    font-size: 1rem;
    font-weight: 700;
    margin-right: auto;
    position: relative;
    margin-bottom: ${theme.space.md};

    ${theme.mediaQueries.medium} {
      margin-bottom: 0;
      margin-right: 3rem;
    }

    :hover {
      color: ${theme.colors.primary} !important;
    }

    &.tab--active:before {
      content: "";
      background-color: ${theme.colors.primary} !important;
      bottom: -0.5rem;
      height: 1px;
      left: 0;
      position: absolute;
      width: 100%;
    }
  }
`;
