import React from "react";
import { RetirementUser } from "../Platform";

export interface ICognitoContext {
  signOut: () => void;
  hasLoaded: boolean;
  hasError: boolean;
  tradingAllowed: boolean;
  retirementUser: RetirementUser;
  setHasError: (value: boolean) => void;
  loadData: (key: string) => string;
  saveData: (key: string, value: string) => void;
  refreshRetirementUser: (jwt: string) => void;
}

export const CognitoContext = React.createContext<ICognitoContext>({
  signOut: () => null,
  hasLoaded: false,
  hasError: false,
  tradingAllowed: false,
  retirementUser: null,
  setHasError: (value) => null,
  loadData: (key) => "",
  saveData: (key, value) => null,
  refreshRetirementUser: (jwt) => Promise.resolve(),
});
