import {
  PropsWithChildren,
  ReactElement,
  createContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  ArrayQueue,
  ConstantBackoff,
  Websocket,
  WebsocketBuilder,
} from "websocket-ts";
import { RetirementUser } from "../Platform";

export interface IWebSocketContext {
  websocket: Websocket;
}

export const WebSocketContext = createContext<IWebSocketContext>({
  websocket: null,
});

interface Props extends PropsWithChildren {
  user?: RetirementUser;
  overlayChannel?: string;
}

export const WebSocketProvider = ({
  user,
  overlayChannel,
  children,
}: Props): ReactElement => {
  const ws = useRef(null);
  const [websocket, setWebsocket] = useState<Websocket>(null);

  const closeConnection = () => {
    if (ws.current) {
      ws.current.close();
    }
  };

  const isLocalWS = process.env["REACT_APP_WEBSOCKET_LOCAL_ENABLED"] === "true";
  const isRemoteWS =
    process.env["REACT_APP_WEBSOCKET_REMOTE_ENABLED"] === "true";

  useEffect(() => {
    if ((isLocalWS || isRemoteWS) && user) {
      const endpoint = isLocalWS
        ? process.env["REACT_APP_WEBSOCKET_LOCAL_ENDPOINT"]
        : process.env["REACT_APP_WEBSOCKET_REMOTE_ENDPOINT"];
      let url = "";

      url = `${endpoint}?guid=${user.name}`;

      closeConnection();

      ws.current = new WebsocketBuilder(url)
        .withBackoff(new ConstantBackoff(5000))
        .withBuffer(new ArrayQueue())
        .build();

      setWebsocket(ws.current);
    } else if ((isLocalWS || isRemoteWS) && overlayChannel) {
      const endpoint = isLocalWS
        ? process.env["REACT_APP_WEBSOCKET_LOCAL_ENDPOINT"]
        : process.env["REACT_APP_WEBSOCKET_REMOTE_ENDPOINT"];
      let url = "";

      url = `${endpoint}?overlay=true&channels=${overlayChannel}`;

      closeConnection();

      ws.current = new WebsocketBuilder(url)
        .withBackoff(new ConstantBackoff(5000))
        .withBuffer(new ArrayQueue())
        .build();

      setWebsocket(ws.current);
    } else {
      closeConnection();
    }

    return () => {
      closeConnection();
    };
  }, [isLocalWS, isRemoteWS, user, overlayChannel]);

  return (
    <WebSocketContext.Provider
      value={{
        websocket,
      }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};
