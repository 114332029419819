import { css } from "styled-components";

export interface IQueries {
  extraSmall?: string;
  small?: string;
  medium?: string;
  large?: string;
  extraLarge?: string;
  xxLarge?: string;
}

export const appBreakpoints = {
  extraSmall: 375,
  small: 564,
  medium: 768,
  large: 992,
  extraLarge: 1200,
  xxLarge: 2000,
};

const mediaQueries: IQueries = {
  extraSmall: `@media all and (min-width: ${appBreakpoints.extraSmall}px)`,
  small: `@media all and (min-width: ${appBreakpoints.small}px)`,
  medium: `@media all and (min-width: ${appBreakpoints.medium}px)`,
  large: `@media all and (min-width: ${appBreakpoints.large}px)`,
  extraLarge: `@media all and (min-width: ${appBreakpoints.extraLarge}px)`,
  xxLarge: `@media all and (min-width: ${appBreakpoints.xxLarge}px)`,
};

const mediaQueriesMax: IQueries = {
  extraSmall: `@media all and (max-width: ${appBreakpoints.extraSmall}px)`,
  small: `@media all and (max-width: ${appBreakpoints.small}px)`,
  medium: `@media all and (max-width: ${appBreakpoints.medium}px)`,
  large: `@media all and (max-width: ${appBreakpoints.large}px)`,
  extraLarge: `@media all and (max-width: ${appBreakpoints.extraLarge}px)`,
  xxLarge: `@media all and (max-width: ${appBreakpoints.xxLarge}px)`,
};

export const theme = {
  container: "1650px",

  font: {
    familyPrimary: "Open Sans",
    // familySecondary: "'Sora', sans-serif",
    familySecondary: "Roboto",
    size: "1rem",
    twitchChat: "Roobert",
  },

  headings: {
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.25rem",
    },
    h4: {
      fontSize: "1rem",
    },
  },

  borders: {
    radius: ".5rem",
  },

  textShadow: {
    default: "0px 8px 8px rgba(0, 0, 0, 0.5)",
  },

  image: {
    size: {
      normal: "2rem;",
      featured: "4rem;",
    },
  },

  avatar: {
    size: {
      normal: "3rem;",
    },
  },

  card: {
    borderRadius: ".25rem",
    size: {
      normal: "200px",
      featured: "100%",
    },
  },

  margin: {
    badgeMargin: {
      margin: ".1rem .25rem 0 0",
      borderRadius: "2px",
      height: "20px",
      width: "20px",
    },
  },

  colors: {
    // bg: "#131722",
    // bodyBg: "#2F1D34",
    bg: "#2F1D34",
    bodyBg: "#131722",
    white: "#FFF",
    offWhite: "#EFEFEF",
    tooltipBlack: "#182027",
    baseWhite: "#fff",
    saltWhite: "#f3f3f3",
    teal: "#31c3a2",
    overlayWhite: "rgba(255,255,255,0.1)",
    translucentWhite: "rgba(255,255,255,0.05)",
    light6: "#D0D0D0",
    blackOverlay: "rgba(0, 0, 0, 0.5)",
    blackLighterOverlay: "rgba(0, 0, 0, 0.25)",
    black: "#000",
    black2: "#222326",
    offBlack: "#3A484E",
    lightBlue: "#0099E0",
    darkBlue: "#09566D",
    grey: "#a4a4a4",
    seasonedGrey: "#2E3941",
    saltAndPepperGrey: "#8C9199",
    crackedPepper: "#07131C",
    transparent: "#00000000",
    translucentGrey: "rgba(26, 36, 44, 0.6)",
    macaronMagenta: "#D6067B",

    // Brand Specific
    primary: "var(--primaryColor)",
    // primary: "#DE0029",
    secondary: "var(--secondaryColor)",
    tertiary: "#F3C202",
    purple: "#AD77D5",
    cyan: "#04A4D4",
    danger: "#f00",
    backgroundDarkBlur: "rgba(0,0,0,0.8)",
    twitchBase: "#9146FF",
    twitchHighlight: "#7313FF",
    googleBase: "#4285F4",
    googleHighlight: "#3367D6",
    playstationBlue: "#0070D1",
    playstationRed: "#DE0029",
    playstationGreen: "#00AA9E",
    playstationYellow: "#F3C202",
    playstationGreyLight: "#ADADAD",
    playstationGreyDark: "#6C6C6C",
    discordPurple: "#5865F2",
    discordBlack: "#23272A",

    streamlabsBase: "#80F5D2",
    streamlabsHighlight: "#47F1BE",

    chatbot: "#1E90FF",
  },

  space: {
    sm: "0.5rem",
    md: "1rem",
    lg: "2rem",
    divider: "8rem",
  },

  zIndex: {
    modal: 10,
  },

  layout: {
    headerHeight: "var(--layoutHeaderHeight)",
    sidebarWidth: "var(--layoutSidebarWidth)",
    scrollbarWidth: "var(--scrollbarWidth)",
  },

  mediaQueries,
  mediaQueriesMax,
  appBreakpoints,
};

export const gradients = {
  brand: `linear-gradient(135deg, ${theme.colors.primary} 0%, ${theme.colors.secondary} 100%)`,
  psBlue: `linear-gradient(135deg, ${theme.colors.playstationBlue} 0%, ${theme.colors.twitchBase} 100%)`,
};

export const containerize = () => {
  return css`
    margin: 0 auto;
    max-width: ${theme.container};
    padding-left: ${theme.space.md};
    padding-right: ${theme.space.md};
    // width: 100%;

    @media all and (min-width: ${theme.appBreakpoints.medium}px) {
      padding-left: ${theme.space.lg};
      padding-right: ${theme.space.lg};
    }
  `;
};

export type themeTypes = typeof theme;
export default theme;
