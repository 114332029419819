import { ComponentType } from "react";

export enum RouteURLS {
  HOME = "/",

  // Admin urls
  ACCOUNT_ADMIN = "/account/admin",
  ADMIN_LOGIN = "/admin/login",
  DEV = "/dev",

  // Account Specific
  ACCOUNT = "/account",
  SUGGESTIONS = "/suggestions",
  STOCKS = "/stocks",
  ACTIVITY = "/activity",
  LOGIN = "/sign-in",
  LOGOUT = "/logout",
  SETTINGS = "/settings",
  SETTINGS_PROFILE = "/settings/profile",
  SETTINGS_SUBSCRIPTION = "/settings/subscription",
  SETTINGS_IDENTITY = "/settings/identity",

  // Applicaton pages
  CHANNEL_BLANK = "/channel",

  // General Terms & Conditions
  COOKIE_POLICY = "/cookie-policy",
  LICENSE_SERVICE_AGREEMENT = "/license-services-agreement",
  PRIVACY = "/privacy",
  TERMS = "/terms",

  // Checkout Routes
  CHECKOUT = "/checkout",
  CHECKOUT_STRIPE_REDIRECT = "/checkout/complete",

  // Other
  NOT_FOUND = "/not-found",
  LIVE = "/live",
  OVERLAY = "/overlay",
  TICKER = "/ticker",
}

export type Routes = {
  component?: ComponentType<any> | undefined;
  exact?: boolean;
  path?: RouteURLS | string;
  strict?: boolean;
  redirect?: {
    from: RouteURLS;
    to: RouteURLS | string;
  };
  meta?: Record<string, unknown>;
};
