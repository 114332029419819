import { AccountPage } from "./pages/Account/Account";
import { AdminLoginPage } from "./pages/Admin/AdminLogin";
import { CheckoutSuccess } from "./pages/CheckoutSuccess/CheckoutSuccess";
import { LandingPage } from "./pages/Landing/Landing";
import { TermsPage } from "./pages/Terms/Terms";
import { SignInPage } from "./pages/SignIn/SignIn";
import { Routes, RouteURLS } from "./types";
import { ActivityPage } from "./pages/Activity/Activity";

export const navItems = [
  { label: "Home", href: "/" },
  // { label: "Pricing", href: RouteURLS.PRICING },
];

export const routes: Routes[] = [
  {
    component: LandingPage,
    path: RouteURLS.HOME,
  },
  // {
  //   component: PricingPage,
  //   path: RouteURLS.PRICING,
  // },
  {
    component: CheckoutSuccess,
    path: RouteURLS.CHECKOUT_STRIPE_REDIRECT,
  },
  // {
  //   component: DevPage,
  //   path: RouteURLS.DEV,
  // },
  {
    component: SignInPage,
    path: RouteURLS.LOGIN,
  },

  // Account Specific
  {
    component: AccountPage,
    path: RouteURLS.ACCOUNT,
    exact: false,
  },
  {
    component: AccountPage,
    path: RouteURLS.SUGGESTIONS,
    exact: false,
  },
  {
    component: AccountPage,
    path: RouteURLS.STOCKS,
    exact: false,
  },
  {
    component: ActivityPage,
    path: RouteURLS.ACTIVITY,
    exact: false,
  },
  // {
  //   component: LandingPage,
  //   path: RouteURLS.LOGIN,
  //   redirect: {
  //     from: RouteURLS.LOGIN,
  //     to: window.location.hash,
  //   },
  // },
  // // {
  // //   component: Logout,
  // //   path: RouteURLS.LOGOUT,
  // // },

  // // Channel pages
  // {
  //   component: ChannelPage,
  //   path: RouteURLS.CHANNEL_BLANK,
  // },
  // {
  //   component: ChannelPage,
  //   path: RouteURLS.CHANNEL,
  // },
  // {
  //   component: ChannelModPage,
  //   path: RouteURLS.CHANNEL_MOD,
  //   exact: true,
  // },
  // {
  //   component: ChannelPage,
  //   path: RouteURLS.CHANNEL_GOAL_DETAIL,
  // },
  {
    component: AdminLoginPage,
    path: RouteURLS.ADMIN_LOGIN,
    exact: true,
  },
  {
    component: TermsPage,
    path: RouteURLS.TERMS,
    exact: true,
  },
  // {
  //   component: TermsPage,
  //   path: RouteURLS.PRIVACY,
  //   exact: true,
  // },

  // 404 page
  // {
  //   component: Generic404Page,
  //   path: "*",
  // },
];
