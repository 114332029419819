const StorageKeys = {
  UPSELL_MODAL: "UPSELL_MODAL",
  UPSELL_MODAL_TIME_UP: "UPSELL_MODAL_TIME_UP",
  FREE_TIER_UPDATES_MODAL: "FREE_TIER_UPDATES_MODAL",
};

export enum UserAgreementStorageKeys {
  USER_LICENSE_AGREEMENT_TIME = "retirement_www_has_user_agreed_to_license",
  OPTED_OUT_OF_MARKETING = "retirement_www_has_user_opted_out_of_marketing",
}

export default StorageKeys;
