import React, { Component, ReactNode } from "react";
import { flowRight as compose } from "lodash";
import * as Sentry from "@sentry/browser";
import { Box, Grommet, Heading, PageContent } from "grommet";
import { retirementGrommetTheme } from "../../Styled";
import { Button } from "../../components";
import { Card } from "../../components/Card/Card";
import * as Prebuilt from "../../components/PreBuilt";
import { ICognitoContext } from "./Context";
import { withCognitoProvider } from "./withCognitoProvider";

interface PublicProps {
  unloadApp: () => void;
  children: ReactNode;
}

interface State {
  error: boolean;
}

type PropsFromContext = Pick<ICognitoContext, "retirementUser">;

class RetirementBasePresentational extends Component<
  PublicProps & State & PropsFromContext
> {
  override state: State = { error: false };

  public override componentDidCatch(
    error: Error,
    errorInfo: React.ErrorInfo
  ): void {
    console.error(error, errorInfo);
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    this.setState({ error: true });
  }

  public override render() {
    if (this.state.error) {
      return (
        <Grommet full theme={retirementGrommetTheme}>
          <Prebuilt.Page>
            <PageContent alignContent="stretch" style={{ zIndex: 2 }}>
              <Box direction="row" pad="small" gap="small" alignSelf="center">
                <Card direction="column">
                  <Heading level={3}>Woops, something went wrong!</Heading>
                  <Heading level={4} alignSelf="center">
                    Don't worry we've captured a log of the problem!
                  </Heading>
                  <Box
                    direction="row-responsive"
                    gap="medium"
                    alignSelf="center"
                  >
                    <Box align="center">
                      <Button
                        id="button-reload-app"
                        color="primary"
                        onClick={this.props.unloadApp}
                      >
                        Reload
                      </Button>
                    </Box>
                  </Box>
                </Card>
              </Box>
            </PageContent>
          </Prebuilt.Page>
        </Grommet>
      );
    }
    return (
      <>
        {/* <UserMessageHandler /> */}
        {this.props.children}
      </>
    );
  }
}

function mapContextToProps(c: ICognitoContext): PropsFromContext {
  return {
    retirementUser: c.retirementUser,
  };
}

export const RetirementBase = compose(withCognitoProvider(mapContextToProps))(
  RetirementBasePresentational
);
