import { FC, HTMLAttributes, useContext } from "react";
import { Provider } from "../Button/types";
import { StyledSocialLogin } from "./styled";
import { SocialLoginProps } from "./types";
import styled from "styled-components";
import { Button, SocialIcon } from "..";
import { IconName } from "../SocialIcon/types";
import { Box, ResponsiveContext } from "grommet";
import { useLinkBuilder } from "../../util/linkBuilder";
import { IS_DEV, IS_STAGING } from "../../util";

const SocialLoginContent = styled(Box)``;

const convertIcon = (provider: Provider) => {
  let icon = IconName.twitch;

  switch (provider) {
    case Provider.Twitch:
      icon = IconName.twitch;
      break;
    case Provider.Discord:
      icon = IconName.discord;
      break;
    default:
      icon = IconName.twitch;
      break;
  }

  return icon;
};

export const SocialLogin: FC<
  SocialLoginProps & HTMLAttributes<HTMLFormElement>
> = ({ provider, last, admin, ...rest }) => {
  const size = useContext(ResponsiveContext);
  const { discord, google, twitch, streamlabs } = useLinkBuilder();

  let options: { [key: string]: any } = {
    target: "WEB",
    source: null,
    client_id: "RGG",
    response_type: "code",
    scope: null,
    force_verify: null,
    nonce: null,
    state: null,
    iFrame: null,
    authEngaged: false,
    authPopup: null,
    api_uri: null,
  };

  const queryParams = new URLSearchParams(window.location.search);

  if (window.location.hostname === "localhost") {
    options["target"] = "DEV";
  } else if (window.location.hostname.includes("staging")) {
    options["target"] = "STAGING";
  }

  queryParams.forEach((value, key) => {
    options[key] = value;
  });

  const linkBuilder = (provider: Provider) => {
    if (admin) {
      if (IS_DEV()) {
        return twitch.replace("DEV", "ADMIN");
      } else if (IS_STAGING()) {
        return twitch.replace("STAGING", "ADMIN");
      } else {
        return twitch.replace("WEB", "ADMIN");
      }
    }

    switch (provider) {
      case Provider.Twitch:
        return twitch;
      case Provider.Google:
        return google;
      case Provider.Discord:
        return discord;
      case Provider.Streamlabs:
        return streamlabs;
    }
  };

  console.log(linkBuilder(provider));

  return (
    <StyledSocialLogin action={linkBuilder(provider)} method="POST" {...rest}>
      <Button provider={provider} last={last}>
        <SocialLoginContent direction="row" gap="small" justify="center">
          <SocialIcon icon={convertIcon(provider)} size={20} />
          <span>
            Sign in {size !== "small" && <>with {Provider[provider]}</>}
          </span>
        </SocialLoginContent>
      </Button>
    </StyledSocialLogin>
  );
};
