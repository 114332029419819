import styled from "styled-components";
import { containerize, theme } from "../../Styled";
import { FC } from "react";

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  color: #222326;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${containerize};
`;

const TextContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

const TextContent = styled.div`
  background-color: ${theme.colors.white};
  margin: 6rem auto ${theme.space.lg} auto;
  max-width: 800px;
  padding: ${theme.space.lg};
  border-radius: ${theme.borders.radius};
`;

const PageTitle = styled.h1`
  padding-bottom: 1.5rem;
`;

const LastUpdated = styled.h4`
  padding-bottom: 5px;
`;

const Terms = styled.div`
  padding-top: 5px;

  a {
    color: blue;

    :hover {
      opacity: 0.9;
      text-decoration: underline;
    }
  }
`;

const Term = styled.div``;

const TermText = styled.div`
  font-weight: 500;
  padding: 10px 16px;
`;

const TermTextSection = styled.p`
  padding-bottom: 30px;

  ul {
    padding-left: 50px;
  }
`;

const TermTextSectionTitle = styled.p`
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 15px;
`;

export const TextPageTemplate: FC<{ children }> = ({ children }) => {
  return (
    <PageWrapper>
      <TextContentWrapper>
        <ContentWrapper>
          <TextContent>{children}</TextContent>
        </ContentWrapper>
      </TextContentWrapper>
    </PageWrapper>
  );
};

export const TermsPage = () => {
  return (
    <TextPageTemplate>
      <PageTitle>RETIREMENT TERMS OF USE</PageTitle>
      <Terms>
        <Term>
          <TermText>
            <TermTextSection>
              Thank you for helping McLaffyTaffy Entertainment LLC (“Taffy”)
              inch closer and closer to retirement! By using the services found
              at Retirement.gg (“Retirement”), you agree to the foregoing
              Retirement.gg terms of service (“Terms”). By using Retirement, you
              authorize us to follow any suggestions provided to us by
              Retirement on your behalf regarding the transmission or receipt of
              any funds in your Retirement token balance.
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Stock Gifting</TermTextSectionTitle>
            <TermTextSection>
              Retirement may permit you to send a “gift” of stock (the “Gift”)
              to Taffy. When you send a Gift of stock, you are sending an amount
              of fiat currency that Taffy may use to initiate a self-directed
              purchase of the stock you identified in the Gift. Taffy may
              decline to make a self-directed purchase of the stock and instead
              accept the Gift as cash.
            </TermTextSection>
            <TermTextSection>
              When you send a Gift, the Gift is not a security and Taffy is not
              receiving anything other than fiat currency. Retirement is not a
              brokerage service and accountholders are not able to engage in
              stock trading. Any trade placed by Taffy to acquire stock with the
              Gift is entirely at the direction and instruction of Taffy.
            </TermTextSection>
            <TermTextSection>
              To send a Gift, you must have a Retirement account. To send Gifts
              to Taffy using Retirement, you must successfully open a Retirement
              account and purchase tokens using Retirement’s token purchasing
              portal. Transactions made in the Retirement token purchasing
              portal are made using Stripe, and you must agree to{" "}
              <a
                href="https://stripe.com/en-gb/legal/end-users"
                target="_blank"
                rel="noreferrer"
              >
                Stripe’s Terms of Service
              </a>{" "}
              before purchasing tokens. Retirement does not receive, process, or
              otherwise hold your payment information submitted to Stripe, and
              you agree to hold Retirement harmless and otherwise release
              Retirement from liability arising from errors that arise when
              using Stripe. All tokens purchased in the Retirement portal are
              nonrefundable, even in the event you do not use them.{" "}
            </TermTextSection>
            <TermTextSection>
              When sending a Gift, the particular stock you identify in
              connection with the Gift is chosen at your discretion. Neither the
              Gift nor the stock identified in connection with the Gift
              constitute a recommendation by Retirement or Taffy to buy or sell
              any security or asset. Neither Retirement nor Taffy make
              recommendations or provide investment advice.
            </TermTextSection>
            <TermTextSection>
              Investing is a risky activity and may involve loss. The amount of
              stock purchased by Taffy using the Gift, if any, may lose value,
              and any lost value will not be reimbursed or replaced by Taffy or
              Retirement. Further, the stock’s price may change after you send
              the Gift but before Taffy uses it to make a self-directed purchase
              of the stock you identified. Selling shares or making a Gift may
              have tax implications, and neither Taffy nor Retirement will
              provide any reimbursement or compensation for the tax consequences
              of selling any stock purchased using a Gift, or for making a Gift.
              If you have questions about your particular tax situation you
              should consult a tax advisor.
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Third-Party Tools</TermTextSectionTitle>
            <TermTextSection>
              Retirement may provide you with access to third-party tools over
              which we neither monitor nor have any control nor input. You agree
              and acknowledge that Taffy and Retirement provide access to these
              tools “as is” and “as available” without any representation or
              conditions of any kind, warranties, and without any endorsement.
              Neither Retirement nor Taffy shall have any liability whatsoever
              arising from or relating to your use of third-party tools. All and
              any use by you of the optional tools offered through Retirement is
              entirely at your own risk and discretion. You should ensure you
              are familiar with and have read and approved the terms on which
              tools are provided by the relevant third-party providers. In the
              future, Taffy or Retirement may offer new services or features
              through their websites or other properties that may include the
              release of new tools and resources. Any such new features and/or
              services shall also be subject to these Terms (as may be updated
              from time to time).
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Third-Party Links</TermTextSectionTitle>
            <TermTextSection>
              Certain content and services available via Retirement may include
              materials from third-parties. The thirdparty links and content
              found on Retirement may direct you to third-party websites that
              are not affiliated with Taffy or Retirement. Neither Taffy nor
              Retirement are responsible for examining or evaluating the content
              or accuracy and neither warrants nor will have any responsibility
              or liability for any third-party materials or websites, or for any
              other materials, products, or services from third-parties. Neither
              Taffy nor Retirement are liable for any harm or damages related to
              the use or purchase of goods, services, resources, content, or any
              other transaction made in connection with any third-party
              websites. Please review carefully the third-party’s terms of
              service, policies, and practices and make sure you understand them
              before you engage with the site or make a transaction. All
              complaints, claims, concerns, or questions regarding third-party
              products and services should be directed to the third-party.
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Third-Party Links</TermTextSectionTitle>
            <TermTextSection>
              In order to use Retirement, you must meet a number of conditions,
              including but not limited to:
            </TermTextSection>
            <TermTextSection>
              <ul>
                <li>
                  You must not be in violation of any embargoes, export
                  controls, or other laws of the United States, the Kingdom of
                  Navarre, or other countries having jurisdiction over these
                  Terms, and yourself. For example, if the Office of Foreign
                  Assets Control prohibits conducting financial transactions
                  with nationals, residents, or banks of your country, you must
                  not use Retirement.
                </li>
                <li>
                  You must be the minimum age required to enter into a contract
                  in the area in which you reside, and, in any event, must not
                  be less than 18 years of age. For example, please don’t sign
                  up for Retirement unless you can legally join the army in the
                  United States or buy beer in Canada.
                </li>
                <li>
                  You must not sign up on behalf of a natural person other than
                  yourself.
                </li>
                <li>
                  You must, if signing up on behalf of an organization, be
                  authorized by that organization to bind it to these Terms and
                  you agree that both you and that organization are hereby bound
                  by these Terms and jointly and severally liable for any breach
                  hereof.
                </li>
                <li>
                  You must provide Retirement and Taffy with personal
                  information, payment information, and other information that
                  we deem necessary to provide you with Retirement. This
                  information must be accurate and complete and updated as
                  changes occur. Neither Taffy nor Retirement will take
                  responsibility for inaccurate information provided by you.
                </li>
              </ul>
            </TermTextSection>
            <TermTextSection>
              Your submission of personal information through Retirement is
              governed by the Retirement Privacy Policy, which is available on
              the Retirement Privacy Policy Page. Retirement reserves the right
              to refuse service to anyone at any time.
            </TermTextSection>
            <TermTextSection>
              Further, while using Retirement, you must not:
            </TermTextSection>
            <TermTextSection>
              <ul>
                <li>
                  Violate the laws of the United States, its states (especially
                  Virginia… you’ll find out why below), or any foreign political
                  entity having jurisdiction over these Terms, whether or not
                  the foreign political entity is a country or a subdivision
                  (such as a state or province) or municipality (such as a city,
                  town, county, medieval French fiefdom, or region) of a foreign
                  country.
                </li>
                <li>
                  Post or send anything violent, threatening, pornographic,
                  racist, hateful, or otherwise objectionable according to the
                  opinion of Taffy.
                </li>
                <li>
                  Infringe on anyone’s intellectual property rights, defame
                  anyone, impersonate anyone, or otherwise violate the rights of
                  a third party.
                </li>
                <li>
                  Hack, crack, phish, SQL inject, Botox inject, or otherwise
                  compromise the security or integrity of Retirement’s, Taffy’s
                  or its users’ computers.
                </li>
                <li>
                  Resell, transfer, give away or otherwise disseminate
                  information obtained from Retirement without Taffy’s express
                  written permission.
                </li>
                <li>
                  Scape, aggregate, or otherwise compile data from Retirement
                  using any form of automated tool.
                </li>
                <li>
                  Do anything else that could bring Taffy or Retirement into
                  disrepute or violate the rights of any person, such as suggest
                  that we are in any way associated with Rick. EAT PANT, RICK.
                </li>
                <li>
                  Copy, duplicate, reproduce, resell, or exploit any portion of
                  Retirement, use Retirement, or access to Retirement or any
                  contact on the website through which the service is provided,
                  without the express written permission by Taffy.
                </li>
              </ul>
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>
              Chargebacks and Deposit Disputes
            </TermTextSectionTitle>
            <TermTextSection>
              If you (or someone on your behalf) initiate a chargeback or
              deposit dispute against Taffy or Retirement, you shall be liable
              to Taffy for the full amount of said chargeback or deposit
              dispute, as well as any reasonable attorneys’ fees, collection
              agency fees, court costs, disbursements, and other expenses
              incurred in the enforcement of Taffy’s rights under this section.
              In other words, we follow a strict “no take-backsies” policy, or
              we’ll sic{" "}
              <a
                href="https://twitter.com/MyLawyerFriend"
                target="_blank"
                rel="noreferrer"
              >
                Noah
              </a>{" "}
              on you.{" "}
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Intellectual Property</TermTextSectionTitle>
            <TermTextSection>
              You agree not to copy, distribute, display, disseminate, or
              otherwise reproduce any of the information, code, or underlying
              material on Retirement without receiving Taffy’s prior written
              permission. In other words, don’t copy Taffy’s work. “Retirement”
              and “McLaffyTaffy” are trademarks we own and that are used by us,
              McLaffyTaffy Entertainment LLC, to uniquely identify Retirement
              and Taffy’s business. You agree not to commercially use these
              phrases to offer the same or similar goods or services anywhere
              without Taffy’s prior written consent. Additionally, you agree not
              to use Retirement’s trade dress or copy the look and feel of
              Retirement or its design, without Taffy’s prior written consent.
              You agree that this paragraph goes beyond the governing law on
              intellectual property law and includes prohibitions on any
              competition that violates the provisions of this paragraph,
              including starting your own competing website or business, even if
              it is so much as a melon stand (Taffy might branch out in the
              future so it need to guard its turf). Taffy may revoke its consent
              for your use of its intellectual property, or any other permission
              granted to you under these Terms, at any time. You agree that if
              Taffy so requests, you must take immediate action to remove any
              usage of Taffy’sintellectual property that you may have engaged
              in, even if it would cause a loss to you. Please don’t spray paint
              “Retirement” or “McLaffyTaffy” on your car, because Taffy might
              need to take your whole car if the paint can’t be removed easily.
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>
              Representations and Warranties
            </TermTextSectionTitle>
            <TermTextSection>
              MANY CENTURIES AGO, OLD SILLY MEN IN POWDERED WIGS AND BLACK ROBES
              (WHO THOUGHT THEY WERE SUPER DUPER SPECIAL) DECIDED IT WOULD BE
              REAL SWEET TO TYPE IN ALL CAPS FOR IMPORTANT SECTIONS OF DOCUMENTS
              - SUCH AS WARRANTY SECTIONS LIKE THIS ONE- SO IT’S “CONSPICUOUS”
              AND “EASY TO SEE” AND “STANDS OUT” - EVEN THOUGH ALL SECTIONS IN
              THESE KINDS OF DOCUMENTS ARE IMPORTANT. OTHERWISE, LAWYERS
              WOULDN’T INCLUDE THEM IN THESE TERMS. ANYWAY, TAFFY’S LAWYER NOAH
              (HI NOAH) DECIDED TO FOLLOW THE TRADITION AND TYPE LIKE TAFFY’S
              YELLING AT YOU, BUT TAFFY’S NOT ACTUALLY YELLING AT YOU. PLEASE
              DON’T TAKE THIS THE WRONG WAY.
            </TermTextSection>
            <TermTextSection>
              TAFFY MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE
              MERCHANTABILITY OF RETIREMENT OR FITNESS FOR ANY PARTICULAR
              PURPOSE. YOU AGREE THAT YOU ARE RELEASING TAFFY FROM ANY LIABILITY
              THAT IT MAY OTHERWISE HAVE TO YOU IN RELATION TO OR ARISING FROM
              THESE TERMS OR RETIREMENT, FOR REASONS INCLUDING, BUT NOT LIMITED
              TO, FAILURE OF RETIREMENT, NEGLIGENCE, OR ANY OTHER TORT. TO THE
              EXTENT THAT APPLICABLE LAW RESTRICTS THIS RELEASE OF LIABILITY,
              YOU AGREE THAT TAFFY ARE ONLY LIABLE TO YOU FOR THE MINIMUM AMOUNT
              OF DAMAGES THAT THE LAW RESTRICTS TAFFY’S LIABILITY TO, IF SUCH A
              MINIMUM EXISTS, AND OTHERWISE, THE EQUIVALENT OF 100 BITS ON
              TWITCH.
            </TermTextSection>
            <TermTextSection>
              YOU AGREE THAT TAFFY IS NOT RESPONSIBLE IN ANY WAY FOR DAMAGES
              CAUSED BY THIRD PARTIES WHO MAY USE RETIREMENT, INCLUDING BUT NOT
              LIMITED TO PEOPLE WHO COMMIT INTELLECTUAL PROPERTY INFRINGEMENT,
              DEFAMATION, TORTIOUS INTERFERENCE WITH ECONOMIC RELATIONS, OR ANY
              OTHER ACTIONABLE CONDUCT TOWARDS YOU.
            </TermTextSection>
            <TermTextSection>
              YOU AGREE THAT TAFFY IS NOT RESPONSIBLE FOR ANY FAILURE ON THE
              PART OF A PAYMENT PROCESSOR, INCLUDING STRIPE OR THE CREDIT CARD
              COMPANY OR BANK THAT YOU USE TO FUND RETIREMENT OR STRIPE, TO
              DIRECT PAYMENTS TO THE CORRECT DESTINATION, OR ANY ACTIONS ON
              THEIR PART IN PLACING A HOLD ON YOUR FUNDS.
            </TermTextSection>
            <TermTextSection>
              YOU AGREE THAT TAFFY IS NOT LIABLE FOR ANY FAILURE OF THE GOODS OR
              SERVICES OF TAFFY OR A THIRD PARTY, INCLUDING ANY FAILURES OR
              DISRUPTIONS, UNTIMELY DELIVERY, SCHEDULED OR UNSCHEDULED,
              INTENTIONAL OR UNINTENTIONAL, ON RETIREMENT WHICH PREVENTS ACCESS
              TO RETIREMENT TEMPORARILY OR PERMANENTLY.
            </TermTextSection>
            <TermTextSection>
              YOU AGREE TAFFY WILL NOT BE LIABLE TO YOU OR ANY OTHER PARTY FOR
              ANY SPECIAL, INCIDENTAL, INDIRECT, GENERAL, OR CONSEQUENTIAL
              DAMAGES, INCLUDING, BUT NOT LIMITED TO, DAMAGES OR COSTS INCURRED
              AS A RESULT OF LOSS OF TIME, LOSS OF SAVINGS, LOSS OF PROPERTY,
              LOSS OF DATA OR LOSS OF PROFITS, WHICH MAY ARISE IN CONNECTION
              WITH RETIREMENT, REGARDLESS OF WHETHER TAFFY HAS BEEN APPRISED OF,
              HAD OTHER REASON TO KNOW, OR IN FACT, KNEW OF THE POSSIBILITY OR
              LIKELIHOOD OF SUCH DAMAGES OCCURRING OR WHETHER CLAIMS ARE BASED
              OR REMEDIES ARE SOUGHT IN CONTRACT, NEGLIGENCE, STRICT LIABILITY,
              TORT, PRODUCTS LIABILITY OR OTHERWISE.
            </TermTextSection>
            <TermTextSection>
              THE PROVISION OF RETIREMENT TO YOU IS CONTINGENT ON YOUR AGREEMENT
              WITH THIS AND ALL OTHER SECTIONS OF THESE TERMS. NOTHING IN THE
              PROVISIONS OF THIS “REPRESENTATIONS & WARRANTIES” SECTION SHALL BE
              CONSTRUED TO LIMIT THE GENERALITY OF THE FIRST PARAGRAPH OF THIS
              SECTION.
            </TermTextSection>
            <TermTextSection>
              For jurisdictions that do not allow Taffy to limit its liability:
              Notwithstanding any provision of these Terms, if your jurisdiction
              has provisions specific to waiver or liability that conflict with
              the above then Taffy’s liability is limited to the smallest extent
              possible by law. Specifically, in those jurisdictions not allowed,
              Taffy does not disclaim liability for: (a) death or personal
              injury caused by its negligence or that of any of its officers,
              employees, or agents; or (b) fraudulent misrepresentation; or (c)
              any liability which it is not lawful to exclude either now or in
              the future.
            </TermTextSection>
            <TermTextSection>
              IF YOU ARE A RESIDENT OF A JURISDICTION THAT REQUIRES A SPECIFIC
              STATEMENT REGARDING RELEASE THEN THE FOLLOWING APPLIES. FOR
              EXAMPLE, CALIFORNIA RESIDENTS MUST, AS A CONDITION OF THESE TERMS,
              WAIVE THE APPLICABILITY OF CALIFORNIA CIVIL CODE SECTION 1542,
              WHICH STATES, “A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH
              THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR
              AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER
              MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE
              DEBTOR.” YOU HEREBY WAIVE THIS SECTION OF THE CALIFORNIA CIVIL
              CODE. YOU HEREBY WAIVE ANY SIMILAR PROVISION IN LAW, REGULATION,
              OR CODE THAT HAS THE SAME INTENT OR EFFECT AS THE AFOREMENTIONED
              RELEASE.
            </TermTextSection>
            <TermTextSection>
              AND NOW TAFFY’s LAWYER NOAH (HI AGAIN NOAH) CAN STOP TYPING LIKE
              HE’S YELLING.{" "}
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Indemnity</TermTextSectionTitle>
            <TermTextSection>
              You agree to indemnify and hold Taffy harmless for any claims by
              you or any third party which may arise from or relate to these
              Terms or the provision of Retirement to you, including any damages
              caused by your use of Retirement. You also agree that you have a
              duty to defend Taffy against such claims and Taffy may require you
              to pay for an attorney(s) of Taffy’s choice in such cases. You
              agree that this indemnity extends to requiring you to pay for
              Taffy’s reasonable attorneys’ fees, court costs, and
              disbursements. In the event of a claim such as one described in
              this paragraph, Taffy may elect to settle with the party/parties
              making the claim, and you shall be liable for the damages as
              though Taffy had proceeded with a trial. In other words, if you
              ruin someone’s day and that person sues Taffy, Taffy can just
              settle and send you the bill instead of waiting around in court
              for a man in a black dress to yell at Taffy with an angry
              judgment.
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Legal Disputes</TermTextSectionTitle>
            <TermTextSection>
              The Code of Virginia clearly disfavors rock-paper-scissors as a
              method of resolving legal disputes. Therefore, you agree that any
              dispute arising from or relating to these Terms or your use of
              Retirement will be heard solely by a court of competent
              jurisdiction in the Commonwealth of Virginia. Specifically, where
              the subject matter of a dispute is eligible for it, you agree that
              any disputes shall be heard solely within the lowest civil court
              of competent jurisdiction in Henrico County in Commonwealth of
              Virginia (the “Court”).{" "}
            </TermTextSection>
            <TermTextSection>
              If a dispute claims multiple claims and one or more of those
              claims would be eligible to be heard by the Court, you agree not
              to bring the other claims against us and to instead proceed within
              the Court.
            </TermTextSection>
            <TermTextSection>
              If you would be entitled in a dispute to an amount exceeding the
              monetary jurisdiction of the Court, you agree to waive your right
              to collect any damages in excess of the monetary jurisdiction and
              instead still bring your claim within the Court. You agree that if
              a dispute is eligible to be heard in Court but you would be
              entitled to an additional or alternative remedy in a higher court,
              such as injunctive relief, you will waive your right to that
              remedy and still bring the dispute within the Court.
            </TermTextSection>
            <TermTextSection>
              If you bring a dispute in a manner other than in accordance with
              this section, you agree that we may move to have it dismissed and
              that you will be responsible for Taffy’s reasonable attorneys’
              fees, court costs, and disbursements in doing so.
            </TermTextSection>
            <TermTextSection>
              You agree that the unsuccessful party in any dispute arising from
              or relating to these Terms will be responsible for the
              reimbursement of the successful party’s reasonable attorneys’
              fees, court costs, and disbursements.{" "}
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Force Majeure</TermTextSectionTitle>
            <TermTextSection>
              You agree that we are not liable to you for anything that we may
              otherwise be responsible for, if it is the result of events beyond
              Taffy’s control, including, but not limited to, acts of God, acts
              of dog, zombie apocalypse, war, peace, insurrection, riots,
              terrorism, crime, labor shortages (including lawful and unlawful
              strikes), embargoes, postal disruption, communication disruption,
              disrupted disruption, unavailability of payment processors,
              failure or shortage of infrastructure, shortages of materials,
              catastrophic surpluses of rice pudding, or any other event beyond
              Taffy’s control.
            </TermTextSection>
          </TermText>
        </Term>
        <Term>
          <TermText>
            <TermTextSectionTitle>Miscellaneous</TermTextSectionTitle>
            <TermTextSection>
              In the event that a provision of these Terms are found to be
              unlawful, conflicting with another provision of the Terms, or
              otherwise unenforceable, the Terms will remain in force as though
              it had been entered into without that unenforceable provision
              being included in it. If two or more provisions of these Terms are
              deemed to conflict with each other’s operation, Taffy shall have
              the sole right to elect which provision remains in force. Taffy
              may do this by spinning a wheel of random, or Taffy may simply
              decide to go with whatever’s most beneficial to Taffy. Taffy
              reserves all rights afforded to it under these Terms as well as
              under the provisions of any applicable law. Taffy’s
              non-enforcement of any particular provision or provisions of these
              Terms or any applicable law should not be construed as Taffy’s
              waiver of the right to enforce that same provision under the same
              or different circumstances at any time in the future- this can be
              described as the “anti-copycat rule.” Please don’t breach these
              Terms because you did it before or saw someone else do it. If
              Taffy didn’t sue the first time, it might be because Taffy didn’t
              notice or Taffy’s owner was sipping bourbon and feeling
              uncharacteristically euphoric that day. There is no guarantee that
              Taffy won’t enforce our rights against subsequent offenders.
            </TermTextSection>
            <TermTextSection>
              Taffy may terminate your access to Retirement at its discretion
              without explanation, though Taffy will strive to provide a timely
              explanation in most cases – tune in for reasons at{" "}
              <a
                href="https://twitter.com/McLaffyTaffy"
                target="_blank"
                rel="noreferrer"
              >
                twitch.tv/McLaffyTaffy
              </a>
              . Taffy’s liability for refunding you, if you have paid anything
              to Taffy, will be limited as herein described, except in cases
              where the termination or cancellation was due to your breach of
              these Terms, in which case you agree that Taffy is not required to
              provide any refund or other compensation whatsoever Under no
              circumstances, including termination or cancellation of the
              provision of Retirement to you, will Taffy be liable for any
              losses related to actions of other users, even if we receive
              advance notice that such users are January 6th Insurrectionists
              and Taffy fails to warn you of an impending raid on your office
              and/or ship. Good luck though, those guys and gals suck.
            </TermTextSection>
            <TermTextSection>
              You may not assign your rights and/or obligations under these
              Terms to any other party without Taffy’s prior written consent.
              You may, however, pop into Taffy’s stream when he’s live and
              request a nod and a knowing smile – just post the following in
              chat: “@McLaffyTaffy, I read the damn thing, where’s my nod and
              smile?” Taffy may assign its rights and/or obligations under these
              Terms to any other party at its discretion. Taffy may do this by
              means of a chat poll, or not at all—most likely the latter because
              Taffy’s a business and enjoys (well, tolerates) working with
              users.{" "}
            </TermTextSection>
            <TermTextSection>
              Taffy may amend these Terms from time to time with or without
              notice to you other than updating this page. Your continued use of
              our Service shall constitute your acceptance of any such
              amendments, and you are solely responsible for conducting regular
              reviews of this page for such amendments.{" "}
            </TermTextSection>
            <TermTextSection>
              Pursuant to California Civil Code Section 1789.3 and similar laws,
              any questions about pricing, complaints, or inquiries about Taffy
              must be addressed to our agent for notice and sent via certified
              mail to that agent. For our agent’s most current contact
              information, please send a message to{" "}
              <a href="mailto:noah@premackrogers.com">noah@premackrogers.com</a>
              . Lastly, California users are also entitled to the following
              specific consumer rights notice: The Complaint Assistance Unit of
              the Division of Consumer Services of the California Department of
              Consumer Affairs may be contacted in writing at 1625 North Market
              Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or
              (800) 952-5210.
            </TermTextSection>
            <TermTextSection>
              Questions about the Terms should be sent to Taffy’s lawyer Noah
              (for the last time, Hi Noah) via a message to
              noah@premackrogers.com.
            </TermTextSection>
          </TermText>
        </Term>
      </Terms>
      <LastUpdated>Last Modified: May 15, 2023</LastUpdated>
    </TextPageTemplate>
  );
};
