import { Redirect, Route, Switch } from "react-router-dom";
import { Grommet } from "grommet";
import { flowRight as compose } from "lodash";
import { withRouter } from "react-router";
import "react-toastify/dist/ReactToastify.css";
import * as Prebuilt from "../components/PreBuilt";
import { routes } from "../routes";
import { retirementGrommetTheme } from "../Styled";
import { StyledToastContainer } from "../Styled/ToastContainer";

export const RetirementPagePresentation = (): JSX.Element => {
  return (
    <Grommet full theme={retirementGrommetTheme}>
      <Prebuilt.Navigation />
      <Switch>
        {routes.map(({ redirect, exact = true, strict = false, ...rest }) =>
          redirect ? (
            <Redirect
              key={redirect.from}
              from={redirect.from}
              to={redirect.to}
            />
          ) : (
            <Route key={rest.path} exact={exact} strict={strict} {...rest} />
          )
        )}
      </Switch>
      <Prebuilt.Footer />
      <StyledToastContainer
        position="bottom-center"
        autoClose={5000}
        closeOnClick
        draggable
        pauseOnHover
        pauseOnFocusLoss={false}
        theme="dark"
      />
    </Grommet>
  );
};

export const RetirementPage = compose(withRouter)(RetirementPagePresentation);
