import { getBuildVersion } from "../../../util";
import { Box, Text, Footer as GFooter } from "grommet";
import { theme } from "../../../Styled";
import styled from "styled-components";
import { Link } from "./styled";
import { RouteURLS, SocialURLS } from "../../../types";

const BrandText = styled(Text)`
  font-weight: bold;
  font-size: small;
  color: ${theme.colors.white};
`;

const StyledLink = styled(Link)`
  font-weight: bold;
  font-size: small;
  padding-left: 0.5rem;
`;

export const Footer = () => {
  return (
    <GFooter background={theme.colors.bodyBg} pad="small">
      <Box direction="row" gap="xxsmall" justify="center">
        <StyledLink href={RouteURLS.TERMS} target="_blank">
          Terms
        </StyledLink>
        {/* <StyledLink href={RouteURLS.PRIVACY} target="_blank">
          Privacy
        </StyledLink> */}
      </Box>
      <Box align="center" direction="row" gap="xsmall">
        {/* <BrandText alignSelf="center">
          <Link href={SocialURLS.STATUS_PAGE} target="_blank">
            Status Page
          </Link>
        </BrandText> */}
        <StyledLink href={SocialURLS.STATUS_PAGE} target="_blank">
          Status
        </StyledLink>
      </Box>
      <Box align="center" direction="row" gap="xsmall">
        <BrandText alignSelf="center">{getBuildVersion()}</BrandText>
      </Box>
    </GFooter>
  );
};
