import { Box, Text } from "grommet";
import { FC, useEffect, useState } from "react";
import { theme } from "../../Styled";
import { currencyName } from "../../core/Context";
import { useAuth } from "../../core/Context/useAuth";
import { IS_PRODUCTION } from "../../util";
import { toast } from "react-toastify";

export const LiveChecker: FC<{}> = () => {
  const { retirementUser, tradingAllowed, refreshRetirementUser } = useAuth();

  var [date, setDate] = useState(
    new Date().toLocaleString("en-US", { timeZone: "America/New_York" })
  );

  const refreshUser = () => {
    refreshRetirementUser(retirementUser?.retirementJWT);
  };

  // create a function that runs every second to update the time
  useEffect(() => {
    var timer = setInterval(() => {
      let usDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
      });
      setDate(usDate);

      if (
        usDate.split(",")[1] === " 9:30:00 AM" ||
        usDate.split(",")[1] === " 4:00:00 PM"
      ) {
        if (retirementUser) {
          refreshUser();
        }
      }
    }, 1000);
    return function cleanup() {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    const refresh = async () => {
      if (retirementUser) {
        refreshUser();
      }
    };

    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendUserToStripeCheckoutPage = () => {
    if (retirementUser?.email) {
      window.location.href = IS_PRODUCTION()
        ? `${process.env["REACT_APP_STRIPE_CHECKOUT_URL_PRODUCTION"]}?prefilled_email=${retirementUser.email}`
        : `${process.env["REACT_APP_STRIPE_CHECKOUT_URL_STAGING"]}?prefilled_email=${retirementUser.email}`;
    } else {
      toast.error(
        "There was a problem generating a checkout page! Contact Support!"
      );
    }
  };

  const userData = ({ backgroundColor }) => {
    if (!retirementUser) {
      return <></>;
    }

    return (
      <Box
        background={backgroundColor}
        pad={{ horizontal: "small", vertical: "xsmall" }}
        round="xsmall"
        style={{ cursor: "pointer" }}
        onClick={sendUserToStripeCheckoutPage}
      >
        <Text size="small" color={theme.colors.white}>
          Balance
        </Text>
        <Text size="small" color={theme.colors.white}>
          {parseFloat(retirementUser?.tokens).toFixed(2)} {currencyName}
        </Text>
      </Box>
    );
  };

  if (!tradingAllowed) {
    return (
      <Box
        direction="row"
        background={theme.colors.bg}
        pad={{ top: "medium", horizontal: "medium" }}
      >
        <Box
          background={theme.colors.playstationRed}
          pad={{ horizontal: "small", vertical: "xsmall" }}
          round="xsmall"
          margin={{ right: "auto" }}
        >
          {retirementUser ? (
            <Text size="small" color={theme.colors.white}>
              Market Closed
            </Text>
          ) : (
            <Text size="small" color={theme.colors.white}>
              You must be signed in
            </Text>
          )}
          <Text size="small" color={theme.colors.white}>
            {date.split(",")[1]}
          </Text>
        </Box>
        {userData({ backgroundColor: theme.colors.bodyBg })}
      </Box>
    );
  }

  return (
    <Box
      direction="row"
      background={theme.colors.bg}
      pad={{ top: "medium", horizontal: "medium" }}
    >
      <Box
        background={theme.colors.playstationGreen}
        pad={{ horizontal: "small", vertical: "xsmall" }}
        round="xsmall"
        margin={{ right: "auto" }}
      >
        <Text size="small" color={theme.colors.white}>
          Market Open
        </Text>
        <Text size="small" color={theme.colors.white}>
          {date.split(",")[1]}
        </Text>
      </Box>
      {userData({ backgroundColor: theme.colors.bodyBg })}
    </Box>
  );
};
