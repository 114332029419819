import { url } from "../core/Context";
import { IS_DEV, IS_STAGING } from "./useEnvironment";

export const useLinkBuilder = () => {
  const provider = "SPECIFY_PROVIDER_HERE";
  const clientid = "RGG";

  let auth: string;

  if (IS_DEV()) {
    auth = `/auth/${provider}?target=DEV`;
  } else if (IS_STAGING()) {
    auth = `/auth/${provider}?target=STAGING`;
  } else {
    auth = `/auth/${provider}?target=WEB`;
  }

  let link = `${url}${auth}&client_id=${clientid}`;

  if (process.env.REACT_APP_API_URL) {
    link = `${process.env.REACT_APP_API_URL}${auth}&client_id=${clientid}`;
  }

  return {
    google: link.replace(provider, "google_oauth2"),
    twitch: link.replace(provider, "twitch"),
    streamlabs: link.replace(provider, "streamlabs"),
    discord: link.replace(provider, "discord").replace(clientid, "DSCRD"),
  };
};
