import * as React from "react";
import { CognitoContext, ICognitoContext } from "./Context";

export function withCognitoProvider<ProvidedProps, P = {}>(mapContextToProps: (c: ICognitoContext, ownProps: P) => ProvidedProps) {
  return (WrappedComponent: React.ComponentType<P>) => {
    return class WithHOCContextConsumer extends React.Component<P> {
      public static displayName = `WithCognitoProvider`;

      public render() {
        return (
          <CognitoContext.Consumer>
            {(value) => {
              const injectedProps = value ? mapContextToProps(value, this.props) : {};
              return <WrappedComponent {...this.props} {...injectedProps} />;
            }}
          </CognitoContext.Consumer>
        );
      }
    };
  };
}
