import { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Anchor,
  Box,
  Header,
  Nav,
  Text,
  Menu,
  ResponsiveContext,
} from "grommet";
import { STORAGE_KEYS } from "../../../core/Platform";
import { theme } from "../../../Styled";
import { FormDown } from "grommet-icons";
import { RouteURLS, Tier } from "../../../types";
import { useHistory } from "react-router-dom";
import { useAuth } from "../../../core/Context/useAuth";
import { isUserEntitled } from "../../../util";
import { navItems } from "../../../routes";
import { url } from "../../../core/Context";

interface SubMenu {
  label: string;
  href?: string;
  onClick?: () => void;
}

export const Navigation = (): JSX.Element => {
  const size = useContext(ResponsiveContext);

  const { retirementUser, saveData } = useAuth();
  const history = useHistory();
  const [userMenu, setUserMenu] = useState<SubMenu[]>();

  const isAdmin = isUserEntitled(retirementUser?.tier, [Tier.Admin]);

  const handleLogout = () => {
    saveData(STORAGE_KEYS.APP_TOKEN, "");
    history.push(RouteURLS.HOME);
    window.location.reload();
  };

  useEffect(() => {
    if (retirementUser) {
      let setupMenu = [
        { label: "My Account", href: RouteURLS.ACCOUNT as string },
      ];
      const logoutMenu = { label: "Logout", onClick: handleLogout };

      // @ts-ignore
      setupMenu.push(logoutMenu);

      setUserMenu(setupMenu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retirementUser]);

  return (
    <Header background={theme.colors.bodyBg} height="60px">
      <Nav direction="row" pad={{ horizontal: "medium" }}>
        {navItems?.map((item) => (
          <Anchor
            href={item.href}
            label={item.label}
            key={item.label}
            color={theme.colors.white}
            margin={{ right: "medium" }}
          />
        ))}
      </Nav>
      <Box direction="row" pad={{ horizontal: "small" }}>
        {size !== "small" && isAdmin && (
          <Nav direction="row" align="center" pad={{ horizontal: "medium" }}>
            <Anchor
              href={RouteURLS.ACTIVITY}
              label={"Activity"}
              color={theme.colors.white}
            />
            <Anchor
              href={`${url}/admin`}
              label={"Admin"}
              color={theme.colors.white}
              target="_blank"
            />
          </Nav>
        )}
        {retirementUser?.id && (
          <Box>
            <Box align="center">
              <Menu
                items={userMenu}
                dropBackground={{ color: theme.colors.bodyBg }}
              >
                <Box direction="row" gap="small" pad="small" align="center">
                  <Avatar src={retirementUser.avatar} round="xsmall" />
                  <Text>{retirementUser.name}</Text>
                  <FormDown />
                </Box>
              </Menu>
            </Box>
          </Box>
        )}
        {!retirementUser?.id && (
          <Box
            direction="row"
            align="center"
            gap="small"
            pad={{ horizontal: "medium" }}
          >
            <Anchor
              href={RouteURLS.LOGIN}
              label={"Sign In"}
              color={theme.colors.white}
            />
          </Box>
        )}
      </Box>
    </Header>
  );
};
