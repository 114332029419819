export const IS_DEV = (): boolean => process.env.NODE_ENV === "development";

export const IS_STAGING = (): boolean =>
  window.location.host.includes("staging.retirement.gg");

export const IS_PRODUCTION = (): boolean => {
  if (process.env.REACT_APP_ENVIRONMENT === "production") {
    return true;
  }
  return window.location.host === "retirement.gg";
};

export const IS_OBS = (): boolean => navigator.userAgent.includes("OBS");
