import {
  Box,
  Heading,
  NameValueList,
  NameValuePair,
  Spinner,
  Tab,
  Tabs,
  Text,
} from "grommet";
import { useEffect, useState } from "react";
import { PageContent } from "grommet";
import * as Prebuilt from "../../components/PreBuilt";
import { User, Resources, Money } from "grommet-icons";
import { Card } from "../../components/Card/Card";
import { useHistory } from "react-router-dom";
import { RouteURLS } from "../../types";
import { Button } from "../../components";
import { useAuth } from "../../core/Context/useAuth";
import { TradeComponent } from "./Trades";
import { StocksComponent } from "./Stocks";

enum TabbedMenu {
  Account,
  Suggestions,
  Stocks,
  // Admin,
}

export const AccountPage = () => {
  const { retirementUser, refreshRetirementUser } = useAuth();

  const [checkURL, setCheckURL] = useState(false);
  const [activeIndex, setActiveIndex] = useState(1);

  const history = useHistory();

  const refreshAccount = async (): Promise<void> => {
    if (retirementUser?.retirementJWT) {
      await refreshRetirementUser(retirementUser?.retirementJWT);
    }
    history.push(RouteURLS.HOME);
  };

  useEffect(() => {
    if (retirementUser) {
      switch (window.location.pathname) {
        case RouteURLS.SUGGESTIONS:
          setActiveIndex(TabbedMenu.Suggestions);
          break;
        case RouteURLS.STOCKS:
          setActiveIndex(TabbedMenu.Stocks);
          break;
        default:
          setActiveIndex(TabbedMenu.Account);
          break;
      }
    }
  }, [retirementUser, checkURL]);

  if (!retirementUser?.id) {
    return (
      <Prebuilt.Page>
        <PageContent>
          <Box direction="row" pad="small" gap="small">
            <Card>
              <Box align="center">
                <Spinner size="medium" />
              </Box>
            </Card>
          </Box>
        </PageContent>
      </Prebuilt.Page>
    );
  }

  return (
    <Prebuilt.Page>
      <PageContent>
        <Tabs
          alignControls="start"
          style={{ paddingTop: "10px" }}
          color="brand"
          activeIndex={activeIndex}
        >
          <Tab
            title="Account"
            icon={<User />}
            onClick={() => {
              history.push(RouteURLS.ACCOUNT);
              setCheckURL(!checkURL);
            }}
          >
            <Box direction="row-responsive" pad="small" gap="small">
              <Card>
                <Box pad={"small"}>
                  <Heading level={3} margin={{ bottom: "small" }}>
                    User Info
                  </Heading>
                  <NameValueList>
                    <NameValuePair name="Name">
                      <Text color="text-strong" margin={{ left: "1rem" }}>
                        {retirementUser?.name}
                      </Text>
                    </NameValuePair>
                    <NameValuePair name="User ID">
                      <Text color="text-strong" margin={{ left: "1rem" }}>
                        {retirementUser?.jwt.guid}
                      </Text>
                    </NameValuePair>
                    <NameValuePair name="Tokens">
                      <Text color="text-strong" margin={{ left: "1rem" }}>
                        {parseFloat(retirementUser?.tokens)}
                      </Text>
                    </NameValuePair>
                  </NameValueList>
                </Box>
                <Box direction="row-responsive" pad={"small"}>
                  <Button color="outline" onClick={() => refreshAccount()}>
                    Refresh
                  </Button>
                </Box>
              </Card>
            </Box>
          </Tab>
          <Tab
            title="Suggestions"
            icon={<Resources />}
            onClick={() => {
              history.push(RouteURLS.SUGGESTIONS);
              setCheckURL(!checkURL);
            }}
          >
            <Box direction="row-responsive" pad="small" gap="small">
              <Card>
                <Box pad={"small"}>
                  <Heading level={3} margin={{ bottom: "small" }}>
                    Suggestion Info
                  </Heading>
                  <TradeComponent />
                </Box>
              </Card>
            </Box>
          </Tab>
          <Tab
            title="Stocks"
            icon={<Money />}
            onClick={() => {
              history.push(RouteURLS.STOCKS);
              setCheckURL(!checkURL);
            }}
          >
            <Box direction="row-responsive" pad="small" gap="small">
              <Card>
                <Box pad={"small"}>
                  <Heading level={3} margin={{ bottom: "small" }}>
                    Stocks Info
                  </Heading>
                  <StocksComponent />
                </Box>
              </Card>
            </Box>
          </Tab>
        </Tabs>
      </PageContent>
    </Prebuilt.Page>
  );
};
