import { Box, Heading, Text, Spinner, PageContent } from "grommet";
import { FC, useEffect, useState } from "react";
import * as Prebuilt from "../../components/PreBuilt";
import { Card } from "../../components/Card/Card";
import { useAuth } from "../../core/Context/useAuth";
import { Button } from "../../components";
import { currencyName } from "../../core/Context";
import { useHistory } from "react-router-dom";
import { RouteURLS } from "../../types";
import { delay } from "../../util";

export const CheckoutSuccess: FC<{}> = () => {
  const { retirementUser, refreshRetirementUser } = useAuth();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [refreshed, setRefreshed] = useState<boolean>(false);

  const refreshUser = () => {
    refreshRetirementUser(retirementUser?.retirementJWT);
  };

  useEffect(() => {
    if (!refreshed) {
      const refresh = async () => {
        await delay(1500);
        if (retirementUser) {
          refreshUser();
          setIsLoading(false);
          setRefreshed(true);
        }
      };

      refresh();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retirementUser]);

  useEffect(() => {
    const refresh = async () => {
      await delay(1500);
      if (retirementUser) {
        refreshUser();
        setIsLoading(false);
      }
    };

    refresh();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <Prebuilt.Page>
        <PageContent>
          <Box direction="row" pad="small" gap="small">
            <Card direction="column">
              <Box align="center">
                <Spinner size="medium" />
              </Box>
            </Card>
          </Box>
        </PageContent>
      </Prebuilt.Page>
    );
  }

  if (!retirementUser) {
    return (
      <Prebuilt.Page>
        <PageContent>
          <Box direction="row" pad="small" gap="small">
            <Card direction="column">
              <Box align="center">Something went wrong</Box>
            </Card>
          </Box>
        </PageContent>
      </Prebuilt.Page>
    );
  }

  return (
    <Prebuilt.Page>
      <PageContent alignContent="stretch">
        <Box direction="row" pad="small" gap="small">
          <Card direction="column">
            <Box gap="medium">
              <Heading level={2}>Checkout</Heading>
              <Heading level={3}>Thanks for buying {currencyName}!</Heading>
              <Text>
                They have been added onto your account balance which is now:{" "}
                {parseFloat(retirementUser?.tokens).toFixed(2)} {currencyName}.
              </Text>
              <Box direction="row">
                <Button
                  color="primary"
                  onClick={() => history.push(RouteURLS.HOME)}
                >
                  Search for shares
                </Button>
              </Box>
            </Box>
          </Card>
        </Box>
      </PageContent>
    </Prebuilt.Page>
  );
};
