import { Box, DataTable, Text } from "grommet";
import { useAuth } from "../../core/Context/useAuth";
import { useEffect, useState } from "react";
import { url, version } from "../../core/Context";
import { token } from "../../util";
import { toast } from "react-toastify";
import { LinkDown, LinkUp, Subtract } from "grommet-icons";
import { theme } from "../../Styled";
import { DECIMAL_PLACES } from "./types";
import { Spinner } from "../../components";
import { calculateProfitLossPercentage } from "../../util/calculateProfitLossPercentage";

interface IStock {
  asset: string;
  symbol: string;
  quantity: number;
  value: number;
  cost: number;
}

export const StocksComponent = () => {
  const { retirementUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [stocks, setStocks] = useState<IStock[]>();

  useEffect(() => {
    const fetchStocks = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${url}/api/v${version}/stocks`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        });
        const data = await response.json();
        setStocks(data.stocks);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
      setIsLoading(false);
    };

    if (retirementUser) {
      fetchStocks();
    }
  }, [retirementUser]);

  const checkProfitLossColor = (stock: IStock) => {
    const value = parseFloat(stock.value.toString());
    const cost = parseFloat(stock.cost.toString());

    if (value === cost) {
      return theme.colors.grey;
    }

    if (value > cost) {
      return theme.colors.playstationGreen;
    } else {
      return theme.colors.playstationRed;
    }
  };

  const checkIcon = (stock: IStock) => {
    const value = parseFloat(stock.value.toString());
    const cost = parseFloat(stock.cost.toString());

    if (value === cost) {
      return <Subtract color={theme.colors.grey} />;
    }

    if (value > cost) {
      return <LinkUp color={theme.colors.playstationGreen} />;
    } else {
      return <LinkDown color={theme.colors.playstationRed} />;
    }
  };

  const tableColumns = [
    {
      property: "asset",
      header: <Text>Name</Text>,
    },
    {
      property: "symbol",
      header: <Text>Symbol</Text>,
    },
    {
      property: "cost",
      header: <Text>Cost</Text>,
      render: (data: IStock) => (
        <Text>{parseFloat(data.cost.toString()).toFixed(DECIMAL_PLACES)}</Text>
      ),
    },
    {
      property: "quantity",
      header: <Text>Quantity</Text>,
      render: (data: IStock) => (
        <Text>
          {parseFloat(data.quantity.toString()).toFixed(DECIMAL_PLACES)}
        </Text>
      ),
    },
    {
      property: "value",
      header: <Text>Value Now</Text>,
      render: (data: IStock) => (
        <Text>
          {data.value
            ? parseFloat(data.value.toString()).toFixed(DECIMAL_PLACES)
            : "N/A"}
        </Text>
      ),
    },
    {
      property: "profit_loss",
      header: <Text>Profit/Loss</Text>,
      render: (data: IStock) => (
        <Box direction="row" align="center">
          <>
            <Box direction="column">
              <Text
                color={
                  data.value ? checkProfitLossColor(data) : theme.colors.grey
                }
              >
                {data.value
                  ? (
                      parseFloat(data.value.toString()) -
                      parseFloat(data.cost.toString())
                    ).toFixed(DECIMAL_PLACES)
                  : `N/A`}
              </Text>
            </Box>
            <Box direction="column" margin="xsmall">
              {data.value && checkIcon(data)}
            </Box>
          </>
        </Box>
      ),
    },
    {
      property: "profit_loss_%",
      header: <Text>Profit/Loss %</Text>,
      render: (data: IStock) => (
        <Box direction="row" align="center">
          <>
            <Box direction="column">
              <Text
                color={
                  data.value ? checkProfitLossColor(data) : theme.colors.grey
                }
              >
                {data.value
                  ? `${calculateProfitLossPercentage(data).toFixed(2)}%`
                  : "N/A"}
              </Text>
            </Box>
            <Box direction="column" margin="xsmall">
              {data.value && checkIcon(data)}
            </Box>
          </>
        </Box>
      ),
    },
  ];

  return (
    <Box direction="row" pad="small" gap="small" fill>
      {isLoading ? (
        <Spinner />
      ) : (
        <DataTable columns={tableColumns} data={stocks} paginate />
      )}
    </Box>
  );
};
