import React, { FC } from "react";
import { StyledButton, StyledProviderButton } from "./styled";
import { ButtonProps } from "./types";

export const Button: FC<
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
> = ({ provider, last, children, externalLink, ...rest }) => {
  if (provider) {
    return (
      <StyledProviderButton
        provider={provider}
        last={last}
        tabIndex={1}
        {...rest}
      >
        <span>{children}</span>
      </StyledProviderButton>
    );
  } else {
    return (
      <StyledButton tabIndex={1} {...rest}>
        {children}
      </StyledButton>
    );
  }
};
