export const getBuildVersion = (): string => {
  let buildVersion = "";

  const commit = process.env["REACT_APP_COMMIT"]?.toString() || "";
  const branch = process.env["REACT_APP_BRANCH"]?.toString() || "";

  if (commit || branch) {
    buildVersion += `v${commit.substring(0, 7)} (${branch.split("/").pop()})`;
  } else {
    buildVersion += `v local (dev)`;
  }

  return buildVersion;
};
