import styled from "styled-components";
import { Link as PageLink } from "react-router-dom";
import { theme, containerize } from "../../../Styled";

export const FooterContainer = styled.div`
  // border-top: 1px solid ${theme.colors.translucentWhite};
  border-top: 1px solid ${theme.colors.playstationYellow};
  padding: ${theme.space.lg} 0 ${theme.space.md};
  ${containerize}
  margin-top: ${theme.space.divider};
  width: 80%;
  flex-direction: column;
`;

export const MainHeading = styled.h2`
  margin-bottom: 0.75rem;
  font-size: 1.25rem;
  color: ${theme.colors.offWhite} !important;
  font-weight: bold;
`;

export const SubHeading = styled(MainHeading)`
  font-size: 1rem;
  margin-top: ${theme.space.sm};
`;

export const LinkContainer = styled.div`
  font-size: 0.9rem;
  margin-bottom: 0.1rem;
`;

const LinkStyles = `
    font-weight: 400;
    text-decoration: none;
    color: ${theme.colors.baseWhite} !important;

    &:hover, &:focus {
        color: ${theme.colors.secondary} !important;
    }
`;

export const Link = styled.a`
  ${LinkStyles}
`;

export const RouterLink = styled(PageLink)`
  ${LinkStyles}
`;

export const FooterElement = styled.div`
  margin-bottom: 2rem;

  ${theme.mediaQueries.medium} {
    width: 50%;
    flex-grow: 1;
  }

  ${theme.mediaQueries.large} {
    width: initial;
    margin-bottom: 0;
  }

  ${theme.mediaQueries.extraLarge} {
    flex-grow: initial;
  }
`;

export const Logo = styled(FooterElement)`
  ${theme.mediaQueries.large} {
    flex-grow: 1;
  }
`;

export const SectionDivider = styled(FooterElement)`
  ${theme.mediaQueries.extraLarge} {
    border-right: 1px solid ${theme.colors.translucentWhite};
    padding-right: 3rem;
    margin-right: 3rem;
  }
`;

export const Copyright = styled.div`
  color: ${theme.colors.playstationGreyLight};
  font-size: 0.8rem;
  margin-left: auto;
  align-self: center;
`;

export const DownloadLink = styled(Link)`
  display: flex;
  flex-grow: 1;
  // align-items: center;
`;

export const TopRow = styled.div`
  ${theme.mediaQueries.medium} {
    display: flex;
    flex-wrap: wrap;
  }

  ${theme.mediaQueries.large} {
    flex-wrap: nowrap;
  }
`;

export const BottomRow = styled.div`
  display: flex;
  // align-items: center;
  margin-top: ${theme.space.lg};
`;

export const SocialLinkContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DownloadTitle = styled.div`
  font-size: 0.9rem;
  margin-left: ${theme.space.md};
`;

export const DownloadContainer = styled.div`
  display: flex;
`;

export const MinimalFooterContainer = styled(BottomRow)`
  border-top: 1px solid ${theme.colors.translucentWhite};
  padding: 1.5rem ${theme.space.md};
`;
