import SvgDiscord from "../../assets/icons/Discord";
import SvgTwitch from "../../assets/icons/Twitch";
import { ReactNode } from "react";

export const iconDictionary = {
  twitch: SvgTwitch,
  discord: SvgDiscord,
};

export enum IconName {
  twitch,
  discord,
}

export interface IconProps {
  icon: IconName;
  href?: string;
  size?: number;
  color?: string;
  hoverColor?: string;
  target?: string;
  children?: ReactNode;
  link?: boolean;
}
