import { addStickyBreadcrumb, createContext } from "../../util";
import jwt_decode from "jwt-decode";
import { Tier } from "../../types";
// import * as Sentry from "@sentry/browser";

export type DecodedJwt = Record<string, unknown>;

export interface User {
  jwt: DecodedJwt;
  retirementJWT: string;
}

export interface RetirementUser extends User {
  email: string;
  username: string;
  avatar: string;
  id: string;
  name?: string;
  consent?: {
    legal: boolean;
    marketing: boolean;
  };
  jwt: RetirementJwt;
  platformJWT?: string;
  tier: string;
  banned: boolean;
  uid: string;
  tokens: string;
}

export interface RetirementJwt extends DecodedJwt {
  name: string;
  banned: boolean;
  avatar: string;
  username: string | null;
  test: boolean;
  user_id: string;
  guid: string;
  email: string;
  tier: string;
  hash: string;
  exp: number;
  uid: string;
}

export interface Features {
  songRequests: boolean;
  compactMode: boolean;
}

export interface RetirementUserDetails {
  name_change_eligible: boolean;
  user: {
    user_id: string;
    guid: string;
    name: string;
    hash: string;
    premium: boolean;
    tier: Tier;
    banned: boolean;
    avatar: string;
    username: string;
    test: boolean;
    email: string;
    created_at: string;
    uid: string;
  };
  subscription: {
    provider: string;
    state: string;
    plan: string;
    balance: string | null;
    first_billing_date: string;
    paid_through_date: string;
    next_billing_date: string;
    next_billing_period_amount: number;
    edit_token: string | null;
  };
}

export enum STORAGE_KEYS {
  APP_TOKEN = "retirement_app_token",
}

export interface IRetirementPlatformContext {
  hasFinishedAttemptLoadUser?: boolean;
  loaded: boolean;
  showingAuth: boolean;
  retirementUser?: RetirementUser;
  isUpdateAvailable?: boolean;
  retirementUserDetails?: RetirementUserDetails;
  features: Features;
  loadData: (key: string) => Promise<string>;
  saveData: (key: string, value: string) => void;
  setRetirementUser: (user: RetirementUser) => void;
  refreshRetirementUser: (jwt: string) => void;
  unloadApp: () => void;
  logout?: () => void;
}

export const {
  context: retirementPlatformContext,
  withContext: withRetirementPlatformContext,
  context: { Provider: BaseRetirementPlatformProvider },
  context: { Consumer: RetirementPlatformConsumer },
} = createContext<IRetirementPlatformContext>("RetirementPlatform", {
  hasFinishedAttemptLoadUser: false,
  loaded: false,
  showingAuth: false,
  features: {
    songRequests: false,
    compactMode: false,
  },
  loadData: () => Promise.resolve(""),
  saveData: () => {},
  setRetirementUser: () => {},
  refreshRetirementUser: () => {},
  unloadApp: () => {},
  logout: () => {},
});

export default retirementPlatformContext;

export function parseRetirementJWT(jwt: string): RetirementUser {
  const decoded: any = jwt_decode(jwt);

  // Sentry.configureScope((scope) => {
  //   scope.setUser({
  //     guid: decoded.guid,
  //     name: decoded.name,
  //     username: decoded.username,
  //     tier: decoded.tier,
  //   });
  // });

  addStickyBreadcrumb("ug", decoded.guid);
  if (navigator.serviceWorker) {
    navigator.serviceWorker.ready.then((registration) => {
      registration.active?.postMessage({
        type: "context",
        context: { ug: decoded.guid },
      });
    });
  }

  return {
    id: decoded.guid,
    name: decoded.name,
    email: decoded.email,
    jwt: decoded,
    tier: decoded.tier,
    retirementJWT: jwt,
    banned: decoded.banned,
    avatar: decoded.avatar,
    username: decoded.username,
    uid: decoded.uid,
    tokens: decoded.tokens,
  };
}
