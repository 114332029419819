import { FC, useEffect, useState } from "react";
import TradingViewWidgetCustom from "./TradeChart";

interface TradingChartProps {
  exchange: string;
  symbol: string;
}

export const TradingChart: FC<TradingChartProps> = ({ exchange, symbol }) => {
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  if (!loaded) {
    return <></>;
  }

  return <TradingViewWidgetCustom data={{ exchange, symbol }} />;
};
