// TradingViewWidget.jsx

import React, { useEffect, useRef } from "react";
import { useWindowSize } from "../../util";

let tvScriptLoadingPromise;

const widthOffset = 160;
const heightOffset = 2;

export default function TradingViewWidgetCustom({ data }) {
  const onLoadScriptRef = useRef();
  const { width } = useWindowSize();
  const { exchange, symbol } = data;

  const realExchange = exchange === "ARCA" ? "AMEX" : exchange;

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_bea7c") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          width: width - widthOffset,
          height: width / heightOffset,
          symbol: realExchange + ":" + symbol,
          interval: "D",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "1",
          locale: "en",
          enable_publishing: false,
          allow_symbol_change: true,
          container_id: "tradingview_bea7c",
          details: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, symbol]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_bea7c" />
      <div className="tradingview-widget-copyright">
        Data provided by TradingView
      </div>
    </div>
  );
}
