import { FC, ReactNode } from "react";

import { Page as GPage } from "grommet";
import { theme } from "../../../Styled";

import backgroundImage from "../../../assets/background.png";
import styled from "styled-components";

interface Props {
  children: ReactNode;
  background?: string;
}

const BackgroundImage = styled.div`
  background-image: url(${backgroundImage});
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  width: 100%;
`;

export const Page: FC<Props> = ({ children, background = theme.colors.bg }) => {
  return (
    <GPage height={{ min: "100%" }} background={background}>
      <BackgroundImage />
      {children}
    </GPage>
  );
};
