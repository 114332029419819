import { Box, DataTable, Text } from "grommet";
import { useAuth } from "../../core/Context/useAuth";
import { useEffect, useState } from "react";
import { url, version } from "../../core/Context";
import { token } from "../../util";
import { toast } from "react-toastify";
import { InProgress, LinkDown, LinkUp, Subtract } from "grommet-icons";
import { theme } from "../../Styled";
import { DECIMAL_PLACES } from "./types";
import { Spinner } from "../../components";

interface ITrade {
  asset: string;
  guid: string;
  symbol: string;
  status: string;
  quantity: number;
  value: number;
  cost: number;
  created_at: Date;
}

export const TradeComponent = () => {
  const { retirementUser } = useAuth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [trades, setTrades] = useState<ITrade[]>();

  useEffect(() => {
    const fetchTrades = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${url}/api/v${version}/trades`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token()}`,
          },
        });
        const data = await response.json();
        setTrades(data.trades);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      }
      setIsLoading(false);
    };

    if (retirementUser) {
      fetchTrades();
    }
  }, [retirementUser]);

  const checkProfitLossColor = (trade: ITrade) => {
    if (trade.status !== "filled") {
      return theme.colors.grey;
    }

    const value = parseFloat(trade.value.toString());
    const cost = parseFloat(trade.cost.toString());

    if (value === cost) {
      return theme.colors.grey;
    }

    if (value > cost) {
      return theme.colors.playstationGreen;
    } else {
      return theme.colors.playstationRed;
    }
  };

  const checkIcon = (trade: ITrade) => {
    if (trade.status !== "filled") {
      return <InProgress color={theme.colors.grey} />;
    }

    const value = parseFloat(trade.value.toString());
    const cost = parseFloat(trade.cost.toString());

    if (value === cost) {
      return <Subtract color={theme.colors.grey} />;
    }

    if (value > cost) {
      return <LinkUp color={theme.colors.playstationGreen} />;
    } else {
      return <LinkDown color={theme.colors.playstationRed} />;
    }
  };

  const tableColumns = [
    {
      property: "asset",
      header: <Text>Name</Text>,
    },
    {
      property: "symbol",
      header: <Text>Symbol</Text>,
    },
    {
      property: "cost",
      header: <Text>Cost</Text>,
      render: (data: ITrade) => (
        <Text>{parseFloat(data.cost.toString()).toFixed(DECIMAL_PLACES)}</Text>
      ),
    },
    {
      property: "quantity",
      header: <Text>Quantity</Text>,
      render: (data: ITrade) => (
        <Text>
          {parseFloat(data.quantity.toString()).toFixed(DECIMAL_PLACES)}
        </Text>
      ),
    },
    {
      property: "value",
      header: <Text>Value Now</Text>,
      render: (data: ITrade) => (
        <Text>
          {data.status === "filled"
            ? parseFloat(data.value.toString()).toFixed(DECIMAL_PLACES)
            : `N/A`}
        </Text>
      ),
    },
    {
      property: "profit_loss",
      header: <Text>Profit/Loss</Text>,
      render: (data: ITrade) => (
        <Box direction="row" align="center">
          <>
            <Box direction="column">
              <Text color={checkProfitLossColor(data)}>
                {data.value
                  ? (
                      parseFloat(data.value.toString()) -
                      parseFloat(data.cost.toString())
                    ).toFixed(DECIMAL_PLACES)
                  : `N/A`}
              </Text>
            </Box>
            <Box direction="column" margin="xsmall">
              {checkIcon(data)}
            </Box>
          </>
        </Box>
      ),
    },
    {
      property: "created_at",
      header: <Text>Traded At</Text>,
      render: (data: ITrade) => (
        <Text>{new Date(data.created_at).toDateString()}</Text>
      ),
    },
  ];

  return (
    <Box direction="row" pad="small" gap="small" fill>
      {isLoading ? (
        <Spinner />
      ) : (
        <DataTable columns={tableColumns} data={trades} paginate />
      )}
    </Box>
  );
};
