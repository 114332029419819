import { FC, useCallback, useEffect, useState } from "react";
import { Text, Image } from "grommet";
import styled from "styled-components";
import $ from "jquery";
import { theme } from "../../../../Styled";
import { delay } from "../../../../util";
import { useWebSocket } from "../../../WebSocketContext";
import { Websocket, WebsocketEvent } from "websocket-ts";
import { Action } from "../../types";

const ContentCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 15px;
  width: 100%;
  max-width: 1080px;
  color: white;

  #alert {
    margin-top: 2000px;
  }

  #content {
    text-shadow: 0px 0px 1px #000, 0px 0px 2px #000, 0px 0px 3px #000,
      0px 0px 4px #000, 0px 0px 5px #000;
  }
`;

const imageSize = 300;
const textSize = "4xl";

export const OverlayMessageHandler: FC = () => {
  const { websocket } = useWebSocket();
  const [alerts, setAlerts] = useState([]);
  const [alertEnded, setAlertEnded] = useState(true);

  const numberFormatted = (number: any, places: number): string => {
    return parseFloat(parseFloat(number).toFixed(places)).toLocaleString(
      "en-US",
      {
        useGrouping: true,
        minimumFractionDigits: places,
      }
    );
  };

  useEffect((): void => {
    (async (): Promise<void> => {
      if (alertEnded) {
        let alert = alerts.shift();

        if (alert) {
          setAlertEnded(false);

          await delay(1000);

          $("#user").html(alert.user);
          $("#symbol").html(alert.symbol);

          if (alert.fractionable) {
            $("#wording").html(`$${numberFormatted(alert.cost, 2)}`);
            $("#extra").html(` worth of `);
          } else {
            $("#wording").html(`${numberFormatted(alert.quantity, 0)}`);
            $("#extra").html(` shares of `);
          }

          $("#alert")
            .animate(
              {
                marginTop: "300px",
              },
              "fast"
            )
            .delay(4000)
            .animate({ marginTop: "2000px" }, "fast");

          let audio = document.getElementById("audio") as HTMLAudioElement;
          audio.volume = 0.7;
          audio.load();
          audio.play();

          await delay(5000);
          setAlertEnded(true);
        }
      }
    })();
  }, [alerts, alertEnded]);

  // websocket logic handle, case by case for each function
  const processIncomingMessage = useCallback(
    (i: Websocket, ev: MessageEvent) => {
      const data = JSON.parse(ev.data);

      switch (data.action) {
        case Action.Alert:
          let user = data.data.user;
          let symbol = data.data.symbol;
          let cost = data.data.cost;
          let quantity = data.data.quantity;
          let fractionable = data.data.fractionable;

          const alert = {
            user: user,
            symbol: symbol,
            cost: cost,
            quantity: quantity,
            fractionable: fractionable,
          };

          setAlerts([...alerts, alert]);
          break;
        case Action.Refresh:
          window.location.reload();
          break;
        default:
          console.debug("MessageHandler: Unknown action.");
          break;
      }
    },
    // This will be fine, the alerts behave
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // websocket logic init
  useEffect(() => {
    websocket?.addEventListener(WebsocketEvent.message, processIncomingMessage);

    return () => {
      websocket?.removeEventListener(
        WebsocketEvent.message,
        processIncomingMessage
      );
    };
  }, [websocket, processIncomingMessage]);

  return (
    <>
      <audio
        id="audio"
        controls={false}
        style={{ display: "none" }}
        src="/assets/cash.mp3"
      >
        <source id="source" type="audio/wav" />
      </audio>
      <ContentCenter>
        <div id="alert">
          <div>
            <Image
              src="/assets/taffyDandy112.png"
              width={imageSize}
              height={imageSize}
            />
          </div>
          <div id="content">
            <Text id="user" size={textSize} color={theme.colors.primary} />
            <Text size={textSize}> just bought </Text>
            <Text id="wording" size={textSize} color={theme.colors.primary} />
            <Text id="extra" size={textSize} />
            <Text id="symbol" size={textSize} color={theme.colors.primary} />
            <Text size={textSize}>!</Text>
          </div>
        </div>
      </ContentCenter>
    </>
  );
};
