import { theme } from "../../../Styled/theme";
import styled from "styled-components";

const BannedWrapper = styled.div`
  width: 100%;
  height: 100vh;
  color: ${theme.colors.baseWhite};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  top: 50%;
  left: 50%;
`;

const SiteHeading = styled.p`
  font-size: 2rem;
`;

const LearnMore = styled.p`
  padding-top: 1.5rem;
`;

const ExternalLink = styled.a`
  color: ${theme.colors.primary};
  text-decoration: none;
  cursor: pointer;
`;

export { BannedWrapper, SiteHeading, LearnMore, ExternalLink };
