import { Box, TextInput } from "grommet";
import { FC, useRef, useState } from "react";
import styled from "styled-components";
import { theme } from "../../Styled";

interface DropDownProps {
  options?: string[];
  placeholder?: string;
  onSelected: (value: string) => void;
  name?: string;
  value?: string;
  limitOptionsToCount?: number;
}

interface TextState {
  suggestions: string[];
  text: string;
}

const StyledDropDown = styled.div`
  position: relative;
  border: 1px solid ${theme.colors.white};
  border-radius: ${theme.borders.radius};

  &:hover,
  &.focus {
    border: 1px solid ${theme.colors.primary};
  }

  ul::before {
    content: "";
  }

  ul {
    list-style-type: none;
    text-align: left;
    margin: 0;
    padding: 5px;
    border-top: 1px solid ${theme.colors.primary};
  }

  li {
    padding: 10px 5px;
    cursor: pointer;
  }

  li:hover {
    background: ${theme.colors.bg};
    border-radius: ${theme.borders.radius};
    // text-decoration: underline;
  }
`;

const TextInputNaked = styled(TextInput)<{ color?: string }>`
  border: none;

  &:focus {
    box-shadow: none;
  }

  ${({ color }) =>
    color
      ? `
    color: ${color};
  `
      : ``}
`;

const StyledUL = styled.ul`
  background-color: black;
  z-index: 10;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  position: absolute;
  margin: 2px;
`;

export const TypeAheadDropDown: FC<
  DropDownProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  options,
  placeholder,
  onSelected,
  value = "",
  limitOptionsToCount = 10,
  ...rest
}) => {
  const [state, setState] = useState<TextState>({
    suggestions: [],
    text: value,
  });
  const [show, setShow] = useState(false);

  const symbolInputRef = useRef<HTMLInputElement>(null);

  const onClick = (e: React.FormEvent<HTMLInputElement>) => {
    setShow(!show);
    setState({
      suggestions: show ? options : [],
      text: value,
    });
  };

  const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    let suggestions: string[] = [];
    const value = symbolInputRef.current.value;
    if (value.length > 0) {
      const regex = new RegExp(`${value}`, `gi`);
      suggestions = options?.filter((v) => regex.test(v)) ?? [];
    }
    setState({
      suggestions,
      text: value,
    });

    onSelected(value);
  };

  const suggestionSelected = (value: string) => {
    setState({
      text: value,
      suggestions: [],
    });

    onSelected(value);
  };

  const renderSuggestions = () => {
    const { suggestions } = state;
    if (suggestions?.length === 0) {
      return null;
    }
    return (
      <StyledUL>
        {suggestions
          ?.filter((suggestion, idx) => idx < limitOptionsToCount)
          .map((suggestion) => (
            <li
              key={suggestion}
              onClick={(e) => suggestionSelected(suggestion)}
            >
              {suggestion}
            </li>
          ))}
      </StyledUL>
    );
  };
  return (
    <Box {...rest}>
      <StyledDropDown>
        <TextInputNaked
          onChange={onTextChange}
          onClick={onClick}
          placeholder={placeholder}
          value={state.text}
          type="text"
          ref={symbolInputRef}
        />
        {renderSuggestions()}
      </StyledDropDown>
    </Box>
  );
};

// export const TypeAheadUserDropDown: FC<
//   DropDownProps & React.HTMLAttributes<HTMLDivElement>
// > = ({ stocks, placeholder, name, onSelected, ...rest }) => {
//   const [state, setState] = useState<StockState>({ suggestions: [], text: "" });
//   const [show, setShow] = useState(false);

//   const onClick = (e: React.FormEvent<HTMLInputElement>) => {
//     setShow(!show);
//     setState({
//       suggestions: show ? stocks.sort(dynamicSort("name")) : [],
//       text: "",
//     });
//   };

//   const onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
//     let suggestions: Stock[] = [];
//     const value = e.currentTarget.value;
//     if (value.length > 0) {
//       const regex = new RegExp(`${value}`, `gi`);
//       suggestions = stocks?.sort().filter((v) => regex.test(v.username)) ?? [];
//     }
//     setState({
//       suggestions,
//       text: value,
//     });

//     onSelected(value);
//   };

//   const suggestionSelected = (value: string) => {
//     setState({
//       text: value,
//       suggestions: [],
//       stock: stocks?.filter((s) => s.username === value)[0],
//     });

//     onSelected(value);
//   };

//   const renderSuggestions = () => {
//     const { suggestions } = state;
//     if (suggestions.length === 0) {
//       return null;
//     }
//     return (
//       <StyledUL>
//         {suggestions.map((stock) => (
//           <li
//             key={stock.symbol}
//             onClick={(e) => suggestionSelected(stock.symbol)}
//           >
//             {/* <StockDisplay stock={stock} /> */}
//             {stock.symbol}
//           </li>
//         ))}
//       </StyledUL>
//     );
//   };
//   return (
//     <Box {...rest}>
//       <StyledDropDown>
//         <TextInputNaked
//           onChange={onTextChange}
//           onClick={onClick}
//           placeholder={placeholder}
//           value={state.stock?.name}
//           type="text"
//           name={name}
//         />
//         {renderSuggestions()}
//       </StyledDropDown>
//     </Box>
//   );
// };
