export enum PlatformAction {
  Alert = "alert",
  Challenge = "challenge",
  Debug = "debug",
  Refresh = "refresh",
  Trade = "trade",
  TTS = "tts",
  TTS_EXTRA = "ttsextra",
}

export enum OverlayAction {
  SettingsToggleExplicit = "settings.toggle-explicit",
  SettingsToggleInstrumental = "settings.toggle-instrumental",
  SettingsToggleYouTubeSafe = "settings.toggle-youtube-safe",
}

export type Action = PlatformAction | OverlayAction;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const Action = {
  ...PlatformAction,
  ...OverlayAction,
};

/* Rest */

export type Connection = {
  name: string;
  type: ConnectionType;
  url: string;
};

export enum ConnectionType {
  Local = "local",
  Remote = "remote",
}

export type MessageBase = {
  challenge?: any;
  action: Action;
};

export type Message<T> = {
  options: T;
} & MessageBase;
