import { FC, useEffect, useState } from "react";
import { Switch, Route } from "react-router";
import { Grommet, Box, Heading, Spinner } from "grommet";
import { BrowserRouter, useHistory } from "react-router-dom";
import { RetirementBase, RetirementProvider } from "./core/Context";
import { RetirementPage } from "./pages/RetirementPage";
import * as Prebuilt from "./components/PreBuilt";
import { retirementGrommetTheme, theme } from "./Styled";
import styled from "styled-components";
import { RouteURLS } from "./types";
import { OverlayPage } from "./pages/Overlay/Overlay";
import { IS_OBS } from "./util";
import { TickerPage } from "./pages/Ticker/Ticker";

const ContentCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 15px;
  width: 100%;
  max-width: 880px;
`;

export const App: FC = () => {
  const history = useHistory();
  const [unloaded, setUnloaded] = useState<boolean>(false);

  const unloadApp = () => {
    console.log("unloadApp");

    setUnloaded(true);

    setTimeout(() => {
      setUnloaded(false);
    }, 3000);
  };

  useEffect(() => {
    if (
      IS_OBS() &&
      (window.location.pathname.includes("/overlay") ||
        window.location.pathname.includes("/ticker"))
    ) {
      document.documentElement.style.setProperty(
        "--background",
        theme.colors.transparent
      );
    } else {
      document.documentElement.style.setProperty(
        "--background",
        theme.colors.bg
      );
    }
  }, []);

  if (unloaded) {
    history.push("/");
    return (
      <Grommet full theme={retirementGrommetTheme}>
        <Prebuilt.Page>
          <Box pad="medium" justify="center" align="center">
            <ContentCenter>
              <Heading level={1} fill>
                Retirement GG
              </Heading>
              <Heading level={3} fill>
                Please wait while the app refreshes
              </Heading>
              <Box align="center">
                <Spinner size="medium" />
              </Box>
            </ContentCenter>
          </Box>
        </Prebuilt.Page>
      </Grommet>
    );
  }

  return (
    <BrowserRouter>
      <RetirementProvider>
        <RetirementBase unloadApp={unloadApp}>
          <Switch>
            <Route path={RouteURLS.OVERLAY} component={OverlayPage} />
            <Route path={RouteURLS.TICKER} component={TickerPage} />
            <Route path="*" component={RetirementPage} />
          </Switch>
        </RetirementBase>
      </RetirementProvider>
    </BrowserRouter>
  );
};
