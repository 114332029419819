export interface ButtonProps {
  /** The bg color of the button */
  color?: "primary" | "secondary" | "danger" | "outline" | string;
  /** Use this to render the External Link component */
  externalLink?: string;
  /** Size of the button (default is "md") */
  size?: "icon" | "sm" | "md" | "lg";
  /** Add optional provider button for getting the correct colors for them */
  provider?: Provider;

  last?: boolean;
}

export enum Provider {
  Twitch = 1,
  Discord,
  Google,
  YouTube,
  Streamlabs,
}

export interface ButtonProviderProps {
  /** Add optional provider button for getting the correct colors for them */
  provider: Provider;
  last?: boolean;
}

export interface SignUpButtonProps {
  /** Userdata props */
  // userData?: UserData;
  /** Manual link for a logged in user */
  loggedInLink?: string;
  /** Go to a third party link */
  thirdPartyLink?: string;
  /** VWO pricing page test */
  pricingPageTest?: boolean;
  /** User's JWT */
  token: string;
  checkoutCouponCode?: string;
}
