import React, { FC, ReactNode } from "react";
import styled from "styled-components";
import { theme } from ".";
import { PageIntro } from "../components/PageIntro";
import { PageIntroProps } from "../components/PageIntro/types";

export const StyledPageWrapper = styled.main<{
  hasNoTitle?: boolean;
  hasSidebar?: boolean;
}>`
  position: relative;
  padding-top: ${({ hasNoTitle }) => (hasNoTitle ? "1rem" : 0)};
`;

interface Props extends PageIntroProps {
  hasSidebar?: boolean;
  className?: string;
  children: ReactNode;
}

export const PageWrapper: FC<Props> = ({ className, children, ...props }) => (
  <StyledPageWrapper
    hasNoTitle={!props.title}
    hasSidebar={props.hasSidebar}
    className={className}
  >
    {props.title && <PageIntro {...props} />}
    {children}
  </StyledPageWrapper>
);

export const Content = styled.div`
  background-color: ${theme.colors.bodyBg};
  display: flex;
  flex-direction: column;
  grid-area: main;
  justify-content: space-between;
  overflow: hidden auto;
  padding-left: ${theme.space.md};

  ${theme.mediaQueries.medium} {
    padding: 0 ${theme.space.lg};
  }
`;

export const DarkWrapper = styled.div`
  background: linear-gradient(
    125deg,
    ${theme.colors.primary},
    ${theme.colors.secondary}
  );
  color: white;
  position: relative;
  min-height: 100%;
`;
