import { useEffect, useState } from "react";
import moment from "moment";
import qs from "querystring";
import { RetirementUser, parseRetirementJWT, STORAGE_KEYS } from "../Platform";
import { RetirementAuth } from "./RetirementAuth";
import { IS_PRODUCTION } from "../../util";
import { CognitoContext } from "./Context";

export const currencyName = "Tokens";

export const url = IS_PRODUCTION()
  ? "https://api.retirement.gg"
  : "https://api-staging.retirement.gg";

export const version = 1;
const jwtRefreshThresholdDays = 7;

export const RetirementProvider = ({ children }) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const [hasError, setError] = useState(false);
  const [tradingAllowed, setTradingAllowed] = useState(false);
  const [user, setUser] = useState<RetirementUser>();

  useEffect(() => {
    // declare the data fetching function
    const setupData = async () => {
      const hash = qs.parse(window.location.hash.substring(1));
      if (hash && hash["token"]) {
        // @ts-ignore
        const retirementUser = parseRetirementJWT(hash.token);
        await setRetirementUser(retirementUser);
        window.location.replace("/");
        setHasLoaded(true);
        return;
      }

      try {
        const token = window.localStorage.getItem(STORAGE_KEYS.APP_TOKEN);
        if (token) {
          let retirementUser;
          try {
            retirementUser = parseRetirementJWT(token);
          } catch (e: any) {
            console.log(
              "Looks like someone tried messing with their token...."
            );
            e.status = 401;
            throw e;
          }
          const { exp, guid, hash } = retirementUser.jwt;
          const expMoment = moment.unix(exp);
          const now = moment();

          if (
            expMoment.diff(now, "days") > jwtRefreshThresholdDays &&
            guid &&
            hash
          ) {
            await setRetirementUser(retirementUser);
          } else {
            refreshRetirementUser(token);
          }
        }
      } catch (e) {
        console.error(e);
      }
    };
    // call the function
    setupData()
      // make sure to catch any error
      .catch(console.error);

    // refreshTradingClock();

    setHasLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Required from old auth provider
  const setHasError = (value: boolean) => {
    setError(value);
  };

  // Required from old auth provider
  const loadData = (key: string) => {
    return window.localStorage.getItem(key) || "";
  };

  // Required from old auth provider
  const saveData = (key: string, value: string) => {
    window.localStorage.setItem(key, value);
  };

  const saveToken = (value: string) => {
    window.localStorage.setItem(STORAGE_KEYS.APP_TOKEN, value);
  };

  const signOut = () => {
    saveToken("");
    window.location.reload();
  };

  const setRetirementUser = async (retirementUser: RetirementUser) => {
    const response = await accountRetirementJWT(retirementUser.retirementJWT);
    if (response.ok) {
      const data = await response.json();
      setTradingAllowed(data.live);
      saveToken(retirementUser.retirementJWT);
      setUser(retirementUser);
    }
  };

  const accountRetirementJWT = async (jwt: string) => {
    const response = await fetch(`${url}/api/v${version}/account`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });

    if (!response.ok) {
      // User is not valid - they're either screwed with their token or something, need to boot them out
      saveToken("");
      // Refresh the page so the jwt is gone
      window.location.reload();
      // return nothing to stop the rest of the code from running
      return;
    }

    return response;
  };

  const refreshRetirementUser = async (jwt: string) => {
    let retirementUser;
    const options = {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    };
    const response = await fetch(`${url}/api/v${version}/refresh`, options);
    if (!response.ok) {
      const error = Error(response.statusText);
      throw error;
    }
    try {
      const { token } = await response.json();
      try {
        retirementUser = parseRetirementJWT(token);
      } catch (e: any) {
        console.log("Looks like someone tried messing with their token....");
        e.status = 401;
        throw e;
      }
      await setRetirementUser(retirementUser);
    } catch (e: any) {
      throw Error(e);
    }
  };

  return (
    <CognitoContext.Provider
      value={{
        hasLoaded,
        hasError,
        tradingAllowed,
        setHasError,
        retirementUser: user,
        saveData,
        loadData,
        signOut,
        refreshRetirementUser,
      }}
    >
      <RetirementAuth>{children}</RetirementAuth>
    </CognitoContext.Provider>
  );
};
