import { Box, Header, Heading } from "grommet";
import styled from "styled-components";
import * as Prebuilt from "./../../components/PreBuilt";
import { Card } from "../../components/Card/Card";
import { Stocks } from "../../components/Stocks/Stocks";
import { LiveChecker } from "../../components/LiveChecker/LiveChecker";
import { theme } from "../../Styled";

export const Content = styled.div`
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;

  > div:first-of-type {
    margin: auto;
  }
`;

export const LandingPage = () => {
  if (process.env["REACT_APP_MAINTENCE_MODE"] === "true") {
    return (
      <Prebuilt.Page>
        <Box direction="column">
          <Box direction="row" pad="medium">
            <Card pad="medium" gap="medium">
              <Box gap="medium" pad="medium">
                <Heading level={3}>
                  Sorry but we are currently in maintance mode
                </Heading>
                <Heading level={3}>Check back later!</Heading>
              </Box>
            </Card>
          </Box>
        </Box>
      </Prebuilt.Page>
    );
  }

  return (
    <Prebuilt.Page>
      <Box direction="column">
        {process.env["REACT_APP_BANNER"] === "true" && (
          <Box
            direction="column"
            align="center"
            background={theme.colors.lightBlue}
          >
            <Header background={theme.colors.lightBlue} height="60px">
              <Heading level="4" margin="none" color={theme.colors.white} fill>
                RetirementGG is going to be in maintenance mode on the 13th &
                14th while we upgrade our servers.
              </Heading>
            </Header>
          </Box>
        )}
        <LiveChecker />
        <Box pad="medium">
          <Card pad="medium" gap="medium">
            <Box gap="medium" pad="medium">
              <Stocks />
            </Box>
          </Card>
        </Box>
      </Box>
    </Prebuilt.Page>
  );
};
