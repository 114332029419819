import React, { FC } from "react";

import { Box, BoxProps } from "grommet";
import { theme } from "../../Styled";
import { DirectionType, EdgeSizeType, RoundType } from "grommet/utils";

interface CardProps {
  background?: string;
  round?: RoundType;
  pad?: EdgeSizeType;
  direction?: DirectionType;
}

export const Card: FC<
  CardProps & BoxProps & React.HTMLAttributes<HTMLDivElement>
> = ({
  children,
  background,
  round = "small",
  pad = "large",
  direction = "column",
  ...rest
}) => (
  <Box
    round={round}
    pad={pad}
    direction={direction}
    background={background ? background : theme.colors.bodyBg}
    fill
    {...rest}
  >
    {children}
  </Box>
);
