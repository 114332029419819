import {
  Box,
  CheckBox,
  Form,
  FormField,
  Heading,
  ResponsiveContext,
  Text,
} from "grommet";
import { useContext, useEffect, useState } from "react";
import { Button } from "../Button";
import { currencyName, url, version } from "../../core/Context";
import { theme } from "../../Styled";
import { toast } from "react-toastify";
import { useAuth } from "../../core/Context/useAuth";
import { IS_PRODUCTION, token } from "../../util";
import { TradingChart } from "../TradingChart/TradingChart";
import { TypeAheadDropDown } from "../TypeAheadDropDown/TypeAheadDropDown";
import { Link, useHistory } from "react-router-dom";
import { RouteURLS } from "../../types";

interface Trade {
  symbol: string;
  amount: number;
  quantity: number;
  terms: boolean;
}

interface Stock {
  symbol: string;
  name: string;
  exchange: string;
  fractionable: boolean;
}

export const Stocks = () => {
  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const { retirementUser, tradingAllowed, refreshRetirementUser } = useAuth();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);

  const [value, setValue] = useState("");
  const [trade, setTrade] = useState({
    trade: { p: 0 },
    symbol: "",
    exchange: "",
    fractionable: false,
  });

  const [stocks, setStocks] = useState<Stock[]>();
  const [stock, setStock] = useState<Stock>();

  const [tradeValue, setTradeValue] = useState<Trade>();

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const fetchStocks = async () => {
    try {
      setIsLoading(true);

      const data = {
        symbol: value,
      };

      const response = await fetch(`${url}/api/v${version}/stocks/search`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const responseData = await response.json();

      if (responseData.message) {
        toast.warn(responseData.message);
      } else {
        setTrade({
          trade: responseData.trade.trade,
          symbol: responseData.asset.symbol,
          exchange: responseData.asset.exchange,
          fractionable: responseData.asset.fractionable,
        });
        setTradeValue({
          symbol: responseData.asset.symbol,
          amount: 0,
          quantity: 0,
          terms: false,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const refreshAccount = async (): Promise<void> => {
    if (retirementUser?.retirementJWT) {
      await refreshRetirementUser(retirementUser?.retirementJWT);
      history.push(RouteURLS.SUGGESTIONS);
    }
  };

  const submitTrade = async () => {
    setSubmitting(true);
    const data = {
      symbol: tradeValue.symbol,
      amount: parseFloat(tradeValue.amount.toString()),
      quantity: parseFloat(tradeValue.quantity.toString()),
      terms: agreedToTerms,
    };

    try {
      const response = await fetch(`${url}/api/v${version}/orders/submit`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw Error(response.statusText);
      }

      const responseData = await response.json();

      console.log(responseData);

      if (responseData.valid) {
        toast.info(`Suggestion id: ${responseData.trade_id} submitted!`);

        await delay(3000);
        setSubmitting(false);
        await refreshAccount();
      } else {
        setSubmitting(false);
        toast.error(responseData.message);
      }
    } catch (error) {
      setSubmitting(false);
      toast.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  useEffect(() => {
    const populateStocks = async () => {
      try {
        const response = await fetch(`${url}/api/v${version}/stocks/load`);
        if (!response.ok) {
          throw Error(response.statusText);
        }

        const data = await response.json();
        setStocks(data.assets);
      } catch (error) {
        console.error(error);
      }
    };

    populateStocks();
  }, []);

  const onSymbolChange = (value: string) => {
    const symbol = value.split(" - ")[0].split(":")[1];
    setStock(stocks.filter((s) => s.symbol === symbol)[0]);
    setValue(symbol);
  };

  useEffect(() => {
    if (stock) {
      fetchStocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stock, value]);

  const calculateAffordability = (amount: number) => {
    const fractionableAmount = amount / trade.trade.p;
    const nonFractionableAmount = Math.floor(amount / trade.trade.p);

    return trade.fractionable ? fractionableAmount : nonFractionableAmount;
  };

  const sendUserToStripeCheckoutPage = () => {
    if (retirementUser?.email) {
      window.location.href = IS_PRODUCTION()
        ? `${process.env["REACT_APP_STRIPE_CHECKOUT_URL_PRODUCTION"]}?prefilled_email=${retirementUser.email}`
        : `${process.env["REACT_APP_STRIPE_CHECKOUT_URL_STAGING"]}?prefilled_email=${retirementUser.email}`;
    } else {
      toast.error(
        "There was a problem generating a checkout page! Contact Support!"
      );
    }
  };

  return (
    <Box gap="medium" align="start">
      <Heading level={3}>Search for a stock symbol here:</Heading>
      {parseFloat(retirementUser?.tokens) === 0 && (
        <Text color={theme.colors.playstationYellow}>
          * Warning: You do not have any tokens to suggest with. You can search
          as normal but you will not be able to submit any suggestions - Buy
          tokens by clicking on your Balance above
        </Text>
      )}

      <Box gap="medium" fill>
        <TypeAheadDropDown
          placeholder="Symbol or Stock name eg GME or Gamestop"
          options={stocks?.map(
            (s) => s.exchange + ":" + s.symbol + " - " + s.name
          )}
          onSelected={onSymbolChange}
        />
      </Box>
      <Box direction="row-responsive" gap="medium" align="start">
        {trade.symbol && trade.exchange && (
          <Box direction="column" gap="medium">
            {size !== "small" ? (
              <Box gap="small">
                <Text color={theme.colors.grey}>
                  Disclaimer: Chart data is provided by TradingView - Notables
                  are data can be delayed by 15 minutes (see the right sidebar
                  for exact timing - orange icons). There are also cases where
                  the chart will show 'Invalid Symbol', in this case please use
                  the 'Last Traded at' data below the chart to make a decision
                  and/or update the chart to point to the correct Exchange +
                  Symbol via the Symbol Search menu in the top left of the chart
                </Text>
                <TradingChart exchange={trade.exchange} symbol={trade.symbol} />
              </Box>
            ) : (
              <Box>
                <Text color={theme.colors.grey}>
                  *Charts are not supported on small screens
                </Text>
              </Box>
            )}

            <Box gap="small" direction="column" fill>
              <Heading level={3}>Symbol: {trade.symbol}</Heading>

              <Heading level={3}>
                <Box gap="xsmall" direction="row">
                  Last traded at
                  <Heading level={3} color={theme.colors.playstationYellow}>
                    {trade.trade?.p || "N/A"}
                  </Heading>
                  on exchange for
                  <Heading level={3} color={theme.colors.playstationYellow}>
                    1 share
                  </Heading>
                </Box>
              </Heading>
            </Box>
            {retirementUser ? (
              <Box gap="small">
                {!trade.trade?.p && (
                  <Text color={theme.colors.playstationYellow}>
                    *Please use the chart value to make a decision on the price
                    - The last traded at value is not available for this symbol
                  </Text>
                )}
                {parseFloat(retirementUser.tokens) > 0 ? (
                  <Box pad={{ vertical: "small" }} gap="medium">
                    {!trade.fractionable && (
                      <Text color={theme.colors.playstationYellow}>
                        *This stock is not fractionable, you can only buy whole
                        shares
                      </Text>
                    )}
                    <Text>
                      You can suggest Approx:{" "}
                      {calculateAffordability(
                        parseFloat(retirementUser.tokens)
                      ).toFixed(9)}
                      &nbsp;
                      {trade.symbol} in total with your current tokens
                    </Text>
                    <Heading level={4}>
                      Review suggestion of {trade.symbol}:
                    </Heading>
                    <Box direction="row-responsive">
                      <Form
                        value={tradeValue}
                        onChange={(nextValue, { touched }) => {
                          if (trade.fractionable) {
                            if (
                              nextValue.amount >
                              parseFloat(retirementUser.tokens)
                            ) {
                              setTradeValue({
                                symbol: trade.symbol,
                                amount: parseFloat(retirementUser.tokens),
                                quantity: 0,
                                terms: agreedToTerms,
                              });
                            } else {
                              setTradeValue(nextValue);
                            }
                          } else {
                            const affordability = calculateAffordability(
                              parseFloat(retirementUser.tokens)
                            );
                            if (nextValue.quantity > affordability) {
                              if (affordability === 0) {
                                toast.info(
                                  `You need more ${currencyName} to afford 1 share of ${trade.symbol}`,
                                  {
                                    position: "bottom-center",
                                  }
                                );
                              }
                              setTradeValue({
                                symbol: trade.symbol,
                                amount: 0,
                                quantity: affordability,
                                terms: agreedToTerms,
                              });
                            } else {
                              setTradeValue({
                                symbol: trade.symbol,
                                amount: 0,
                                quantity: nextValue.quantity,
                                terms: agreedToTerms,
                              });
                            }
                          }
                        }}
                      >
                        <FormField name="symbol" hidden value={trade.symbol} />
                        {trade.fractionable ? (
                          <FormField
                            label={`${currencyName} redeemed`}
                            name="amount"
                            validate={{ regexp: /^[1-9]/ }}
                          />
                        ) : (
                          <FormField
                            label={`Whole shares`}
                            name="quantity"
                            validate={{ regexp: /^[1-9]/ }}
                          />
                        )}
                        {(tradeValue.amount > 0 || tradeValue.quantity > 0) && (
                          <Box>
                            <Box pad="small" direction="column">
                              <Text weight="lighter">
                                {trade.fractionable ? (
                                  <Box>
                                    Approx shares:&nbsp;{" "}
                                    {calculateAffordability(
                                      tradeValue.amount
                                    ).toFixed(9)}
                                    &nbsp;
                                    {trade.symbol}
                                  </Box>
                                ) : (
                                  <Box>
                                    Approx shares:&nbsp; {tradeValue.quantity}
                                    &nbsp;
                                    {trade.symbol}
                                  </Box>
                                )}
                              </Text>
                              <FormField
                                name="terms"
                                component={CheckBox}
                                label={
                                  <>
                                    I accept the terms outlined in the&nbsp;
                                    <a
                                      href={RouteURLS.TERMS}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      terms of service
                                    </a>
                                  </>
                                }
                                checked={agreedToTerms}
                                onClick={() => {
                                  setAgreedToTerms(!agreedToTerms);
                                }}
                              />
                            </Box>
                            <Box
                              direction="row-responsive"
                              justify="end"
                              pad="medium"
                              margin={{ top: "medium" }}
                            >
                              <Button
                                color="outline"
                                onClick={() => {
                                  setTrade(null);
                                  setTradeValue(null);
                                }}
                                style={{ margin: "5px" }}
                              >
                                Cancel
                              </Button>
                              <Button
                                color="primary"
                                style={{ margin: "5px" }}
                                onClick={submitTrade}
                                disabled={
                                  !tradingAllowed ||
                                  !agreedToTerms ||
                                  isSubmitting
                                }
                              >
                                Submit
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </Form>
                    </Box>
                  </Box>
                ) : (
                  <Box pad={{ vertical: "small" }} gap="medium">
                    <Text>
                      You do not have any {currencyName} to suggest with. Please{" "}
                      <Button
                        color="outline"
                        onClick={sendUserToStripeCheckoutPage}
                      >
                        Buy {currencyName}
                      </Button>{" "}
                      to continue
                    </Text>
                  </Box>
                )}
              </Box>
            ) : (
              <Box gap="small">
                <Box pad={{ vertical: "small" }} gap="medium">
                  <Text>
                    You must be logged in to suggest. Please{" "}
                    <Link to="/sign-in">sign in</Link> to continue
                  </Text>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
