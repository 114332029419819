import { IStock } from "../pages/Ticker/Ticker";

export const calculateProfitLossPercentage = (stock: IStock) => {
  const cost = parseFloat(stock.cost.toString());
  const value = parseFloat(stock.value.toString());

  if (cost === value) {
    return 0; // No change
  } else if (cost === 0) {
    if (value === 0) {
      return 0; // If both values are 0, percentage increase is 0
    } else {
      return Infinity; // If original value is 0 but updated value isn't, percentage increase is infinity
    }
  } else {
    const returnValue = ((value - cost) / cost) * 100;
    if (value > cost) {
      return returnValue;
    } else {
      return returnValue * -1;
    }
  }
};
