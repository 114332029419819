import { Banned } from "../../components/Banned";
import { useAuth } from "./useAuth";

export const RetirementAuth = ({ children }) => {
  const { retirementUser } = useAuth();

  if (retirementUser && retirementUser?.banned) {
    return <Banned />;
  }

  return <>{children}</>;
};
