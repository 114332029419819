import { Box, Heading, PageContent } from "grommet";
import styled from "styled-components";
import { SocialLogin } from "../../components";
import { Provider } from "../../components/Button/types";
import { Card } from "../../components/Card/Card";
import * as Prebuilt from "./../../components/PreBuilt";

const Content = styled.div`
  margin-top: 30px;
`;

export const AdminLoginPage = () => {
  return (
    <Prebuilt.Page>
      <PageContent align="center">
        <Box pad={"medium"} justify="center" align="center" margin={"small"}>
          <Card align="center" direction="column" style={{ zIndex: 2 }}>
            <Heading size="small">Sign in to Admin</Heading>
            <Content role="main">
              <SocialLogin provider={Provider.Twitch} admin />
            </Content>
            {/* <div>
            By signing in to Retirement GG you accept our{" "}
            <a href="//pretzel.rocks/terms" target="_blank" rel="noopener">
              Terms of Use
            </a>{" "}
            and{" "}
            <a href="//pretzel.rocks/privacy" target="_blank" rel="noopener">
              Privacy Policy
            </a>
            .
          </div> */}
          </Card>
        </Box>
      </PageContent>
    </Prebuilt.Page>
  );
};
