import { FC, useCallback, useEffect, useState } from "react";
import { Action } from "../../core/Message";
import { Websocket, WebsocketEvent } from "websocket-ts";
import { token } from "../../util";
import { useAuth } from "../../core/Context/useAuth";
import { url, version } from "../../core/Context";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { Box, Heading, PageContent, Text } from "grommet";
import { Button } from "../../components";
import { FormRefresh } from "grommet-icons";
import { Card } from "../../components/Card/Card";
import * as Prebuilt from "./../../components/PreBuilt";
import { RouteURLS } from "../../types";
import { theme } from "../../Styled";
import { WebSocketProvider } from "../../core/WebSocketContext/WebSocketProvider";
import { useWebSocket } from "../../core/WebSocketContext";

interface IOverlayData {
  user: string;
  symbol: string;
  asset: string;
  quantity: number;
  value: number;
  cost: number;
  created_at: Date;
  id: number;
  fractionable: boolean;
}

export const ActivityPage: FC<{}> = () => {
  return (
    <Prebuilt.Page>
      <PageContent align="center">
        <Box pad={"medium"} justify="center" align="center" margin={"small"}>
          <Card align="center" direction="column" style={{ zIndex: 2 }}>
            <WebSocketProvider>
              <ActivityComponent />
            </WebSocketProvider>
          </Card>
        </Box>
      </PageContent>
    </Prebuilt.Page>
  );
};

const ActivityComponent: FC = () => {
  const history = useHistory();
  const { retirementUser } = useAuth();
  const { websocket } = useWebSocket();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [overlayData, setOverlayData] = useState<IOverlayData[]>();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/api/v${version}/trades/admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
      });
      const data = await response.json();
      setOverlayData(data.trades);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
    setIsLoading(false);
  };

  // websocket logic handle, case by case for each function
  const processIncomingMessage = useCallback(
    (i: Websocket, ev: MessageEvent) => {
      const data = JSON.parse(ev.data);

      switch (data.action) {
        case Action.Trade:
          if (window.location.pathname === RouteURLS.ACTIVITY) {
            fetchData();
          }
          break;
        case Action.Alert:
          console.log("MessageHandler: Alert received - Do nothing here.");
          break;
        case Action.Refresh:
          if (window.location.pathname !== RouteURLS.ACTIVITY) {
            window.location.reload();
          }
          break;
        default:
          console.debug("MessageHandler: Unknown action.");
          break;
      }
    },
    []
  );

  // websocket logic init
  useEffect(() => {
    websocket?.addEventListener(WebsocketEvent.message, processIncomingMessage);

    return () => {
      websocket?.removeEventListener(
        WebsocketEvent.message,
        processIncomingMessage
      );
    };
  }, [websocket, processIncomingMessage]);

  useEffect(() => {
    if (retirementUser) {
      if (retirementUser.tier === "admin") {
        fetchData();
      } else {
        history.push("/account");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [retirementUser]);

  const triggerOverlayAlert = async (data: IOverlayData) => {
    const alertData = {
      trade_id: data.id,
    };

    try {
      const response = await fetch(`${url}/api/v${version}/trades/overlay`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token()}`,
        },
        body: JSON.stringify(alertData),
      });
      const data = await response.json();
      toast.info(data.message);
    } catch (error) {
      console.error(error);
      toast.error(error.message);
    }
  };

  const numberFormatted = (number: any, places: number): string => {
    return parseFloat(parseFloat(number).toFixed(places)).toLocaleString(
      "en-US",
      {
        useGrouping: true,
        minimumFractionDigits: places,
      }
    );
  };

  const calculateBackground = (created_at: Date) => {
    const now = Math.floor(Date.now() / 1000);
    const created_at_epoch = Math.floor(created_at.getTime() / 1000);
    return now - created_at_epoch < 300;
  };

  return (
    <Box direction="row" pad="small" gap="small">
      <Box direction="column" gap="small">
        <Box direction="row" pad="small" gap="small">
          <Heading level="3">Overlay Alerts</Heading>
        </Box>
        {overlayData
          ?.sort((a, b) => b.id - a.id)
          ?.map((data, index) => {
            return (
              <Box
                direction="row"
                key={index}
                style={{
                  borderBottom: "1px solid white",
                  paddingBottom: "8px",
                }}
                alignContent="center"
              >
                <Box direction="column" gap="small" alignSelf="end">
                  <Box direction="row" gap="small" align="center">
                    <Button
                      size="icon"
                      color="none"
                      onClick={() => triggerOverlayAlert(data)}
                    >
                      <FormRefresh />
                    </Button>
                  </Box>
                </Box>
                <Box
                  direction="column"
                  pad="small"
                  alignSelf="center"
                  background={
                    calculateBackground(
                      new Date(
                        data.created_at.toLocaleString("en-US", {
                          timeZone: "America/New_York",
                        })
                      )
                    )
                      ? theme.colors.secondary
                      : ""
                  }
                  style={{ borderRadius: "8px" }}
                >
                  <Text color={theme.colors.grey}>
                    <Text>
                      {new Date(data.created_at).toLocaleString("en-US", {
                        timeZone: "America/New_York",
                      })}{" "}
                    </Text>
                    <Text weight="bold" color={theme.colors.white}>
                      {data.user}
                    </Text>
                    <Text> bought </Text>

                    {data.fractionable ? (
                      <>
                        <Text weight="bold" color={theme.colors.white}>
                          ${numberFormatted(data.cost, 2)}
                        </Text>
                        <Text> worth of </Text>
                      </>
                    ) : (
                      <>
                        <Text weight="bold" color={theme.colors.white}>
                          {numberFormatted(data.quantity, 0)}
                        </Text>
                        <Text> shares of </Text>
                      </>
                    )}
                    <Text weight="bold" color={theme.colors.white}>
                      {data.symbol}
                    </Text>
                    <Text> ({data.asset})</Text>
                  </Text>
                </Box>
              </Box>
            );
          })}
      </Box>
      {/* {overlayData?.sort((a, b) => b.id - a.id)?.map((data) => {
        return 
          <Text key={data.id}>
            ;
      })   */}

      {/* <DataTable
        columns={tableColumns}
        data={overlayData?.sort((a, b) => b.id - a.id)}
        paginate
      /> */}
    </Box>
  );
};
