import { FC, useCallback, useEffect, useState } from "react";
import { Action } from "../../core/Message";
import { Websocket, WebsocketEvent } from "websocket-ts";
import { url, version } from "../../core/Context";
import { Box, Text } from "grommet";
import { LinkDown, LinkUp, Subtract } from "grommet-icons";
import { RouteURLS } from "../../types";
import { theme } from "../../Styled";
import styled from "styled-components";
import ReactTextTransition, { presets } from "react-text-transition";
import Marquee from "react-fast-marquee";
import { WebSocketProvider } from "../../core/WebSocketContext/WebSocketProvider";
import { useWebSocket } from "../../core/WebSocketContext";
import { calculateProfitLossPercentage } from "../../util/calculateProfitLossPercentage";

export interface IStock {
  symbol: string;
  asset: string;
  quantity: number;
  value: number;
  cost: number;
}

const textSize = "115px";
const textSizeMarquee = "25px";
let isMarquee = true;

export const TickerPage: FC<{}> = () => {
  const urlParam = window.location.pathname.split("/")[2];

  return (
    <WebSocketProvider overlayChannel={urlParam}>
      <TickerComponent />
    </WebSocketProvider>
  );
};

const ContentCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 0 15px;
  width: 100%;
  color: white;
  font-family: ${theme.font.familyPrimary};
  max-width: 1080px;

  #content {
    text-shadow: 0px 0px 1px #000, 0px 0px 2px #000, 0px 0px 3px #000,
      0px 0px 4px #000, 0px 0px 5px #000;
  }
`;

const ContentCenterMode = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: white;
  font-family: ${theme.font.familyPrimary};

  #content {
    text-shadow: 0px 0px 1px #000, 0px 0px 2px #000, 0px 0px 3px #000,
      0px 0px 4px #000, 0px 0px 5px #000;
  }
`;

const checkProfitLossColor = (stock: IStock) => {
  const value = parseFloat(stock.value.toString());
  const cost = parseFloat(stock.cost.toString());

  if (value === cost) {
    return theme.colors.grey;
  }

  if (value > cost) {
    return theme.colors.playstationGreen;
  } else {
    return theme.colors.playstationRed;
  }
};

const checkIcon = (stock: IStock) => {
  const value = parseFloat(stock.value.toString());
  const cost = parseFloat(stock.cost.toString());

  if (value === cost) {
    return (
      <Subtract
        size={isMarquee ? "medium" : "xlarge"}
        color={theme.colors.grey}
      />
    );
  }

  if (value > cost) {
    return (
      <LinkUp
        size={isMarquee ? "medium" : "xlarge"}
        color={theme.colors.playstationGreen}
      />
    );
  } else {
    return (
      <LinkDown
        size={isMarquee ? "medium" : "xlarge"}
        color={theme.colors.playstationRed}
      />
    );
  }
};

const TickerSymbol: FC<{ stock: IStock; marquee?: boolean }> = ({
  stock,
  marquee,
}) => {
  isMarquee = marquee;

  if (!stock) {
    return <></>;
  }

  return (
    <Box direction="column">
      <Box direction="row" align="center">
        <Text
          style={
            marquee
              ? {
                  backgroundColor: theme.colors.white,
                  paddingBottom: "5px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                }
              : {
                  backgroundColor: theme.colors.black,
                  paddingBottom: "30px",
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  borderRadius: "10px",
                }
          }
          alignSelf="center"
        >
          {marquee ? (
            <>
              <Text size={textSizeMarquee} color={theme.colors.black}>
                {stock.symbol}&nbsp;
              </Text>
              <Text
                size={textSizeMarquee}
                color={
                  stock.value ? checkProfitLossColor(stock) : theme.colors.grey
                }
              >
                {stock.value
                  ? `${calculateProfitLossPercentage(stock).toFixed(2)}%`
                  : "N/A"}
                &nbsp;
              </Text>
              {stock.value && checkIcon(stock)}
            </>
          ) : (
            <>
              <ReactTextTransition
                springConfig={presets.gentle}
                style={{ margin: "0 4px" }}
                inline
              >
                <Text size={textSize}>{stock.symbol}&nbsp;</Text>
              </ReactTextTransition>
              <ReactTextTransition
                springConfig={presets.gentle}
                style={{ margin: "0 4px" }}
                inline
              >
                <Text
                  size={textSize}
                  color={
                    stock.value
                      ? checkProfitLossColor(stock)
                      : theme.colors.grey
                  }
                >
                  {stock.value
                    ? `${calculateProfitLossPercentage(stock).toFixed(2)}%`
                    : "N/A"}
                  &nbsp;
                </Text>
              </ReactTextTransition>
              <ReactTextTransition
                springConfig={presets.gentle}
                style={{ margin: "0 4px" }}
                inline
              >
                {stock.value && checkIcon(stock)}
              </ReactTextTransition>
            </>
          )}
        </Text>
      </Box>
    </Box>
  );
};

const TickerComponent: FC = () => {
  const { websocket } = useWebSocket();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [overlayData, setOverlayData] = useState<IStock[]>();
  const [displayingStock, setDisplayingStock] = useState<IStock>();
  const [index, setIndex] = useState(0);
  const [mode, setMode] = useState("0");

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${url}/api/v${version}/stocks/ticker`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setOverlayData(
        data.stocks.filter((stock: IStock) => stock.value).reverse()
      );
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  }, []);

  // create a function that runs every second to update the time
  useEffect(() => {
    if (overlayData) {
      var timer = setInterval(() => {
        if (index === overlayData.length - 1) {
          fetchData();
          setIndex(0);
          setDisplayingStock(overlayData[index]);
        } else {
          setIndex(index + 1);
        }
        setDisplayingStock(overlayData[index]);
      }, 15000);
      return function cleanup() {
        clearInterval(timer);
      };
    }
  }, [overlayData, index, fetchData]);

  useEffect(() => {
    setMode(new URLSearchParams(window.location.search).get("mode"));
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // websocket logic handle, case by case for each function
  const processIncomingMessage = useCallback(
    (i: Websocket, ev: MessageEvent) => {
      const data = JSON.parse(ev.data);

      switch (data.action) {
        case Action.Alert:
        case Action.Trade:
          console.log(
            "MessageHandler: Alert/Trade received - Do nothing here."
          );
          break;
        case Action.Refresh:
          if (window.location.pathname !== RouteURLS.ACTIVITY) {
            window.location.reload();
          }
          break;
        default:
          console.debug("MessageHandler: Unknown action.");
          break;
      }
    },
    []
  );

  // websocket logic init
  useEffect(() => {
    websocket?.addEventListener(WebsocketEvent.message, processIncomingMessage);

    return () => {
      websocket?.removeEventListener(
        WebsocketEvent.message,
        processIncomingMessage
      );
    };
  }, [websocket, processIncomingMessage]);

  if (isLoading) {
    return null;
  }

  if (mode === "1") {
    return (
      <ContentCenterMode>
        <Marquee gradientColor={[255, 255, 255]}>
          {overlayData?.map((stock: IStock) => (
            <TickerSymbol stock={stock} marquee />
          ))}
        </Marquee>
      </ContentCenterMode>
    );
  }

  return (
    <ContentCenter>
      {displayingStock && <TickerSymbol stock={displayingStock} />}
    </ContentCenter>
  );
};
