import { FC } from "react";
import { UserAvatarProps } from "./types";
import styled from "styled-components";
import { theme } from "../../Styled";

const StyledUserAvatar = styled.img`
  width: ${theme.avatar.size.normal};
  height: ${theme.avatar.size.normal};
  border-radius: ${theme.borders.radius};
`;

export const UserAvatar: FC<UserAvatarProps> = ({ src, ...rest }) => {
  return <StyledUserAvatar src={src} {...rest} />;
};
