import styled from "styled-components";
import { theme } from "../../Styled";

export const Link = styled.a<{ hoverColor?: string }>`
  margin-left: 1rem;
  text-decoration: none;
  color: ${theme.colors.baseWhite} !important;

  &:hover,
  &:focus {
    color: ${({ hoverColor }) =>
      hoverColor ? hoverColor : theme.colors.primary} !important;

    svg {
      rect {
        stroke: ${({ hoverColor }) =>
          hoverColor ? hoverColor : theme.colors.primary};
      }
      path {
        fill: ${({ hoverColor }) =>
          hoverColor ? hoverColor : theme.colors.primary};
      }
    }
  }
`;
