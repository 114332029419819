const breadcrumbLimit = 10;
const breadcrumbs: string[] = [];
const stickyBreadcrumbs: { [key: string]: string } = {};

export function addStickyBreadcrumb(key: string, message: string) {
  stickyBreadcrumbs[key] = message;
}

export function addBreadcrumb(message: string) {
  breadcrumbs.push(message);
  if (breadcrumbs.length > breadcrumbLimit) {
    breadcrumbs.shift();
  }
}

export function getBreadcrumbPackage() {
  const s = Object.keys(stickyBreadcrumbs)
    .map(k => `${k}=${stickyBreadcrumbs[k]}`)
    .join(',');
  return btoa(JSON.stringify({ s, b: breadcrumbs }));
}
