import { useState } from "react";

let cookieDomain = ".retirement.gg";

switch (process.env["NODE_ENV"]) {
  case "production":
    cookieDomain = ".retirement.gg";
    break;
  case "test":
    cookieDomain = ".retirement.gg";
    break;
}

export const getCookieItem = (key: string) =>
  document.cookie.split("; ").reduce((total, currentCookie) => {
    const item = currentCookie.split("=");
    const storedKey = item[0];
    const storedValue = item[1];
    return key === storedKey ? decodeURIComponent(storedValue) : total;
  }, "");

export const setCookieItem = (
  key: string,
  value: string,
  numberOfDaysTillExpire: number,
  domain = cookieDomain,
  forceSameSiteSecure = false
) => {
  const now = new Date();
  now.setTime(now.getTime() + numberOfDaysTillExpire * 60 * 60 * 24 * 1000);
  document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/; domain=${domain}${
    forceSameSiteSecure ? "; SameSite=None; Secure" : ""
  }`;
};

export const deleteCookieItem = (
  key: string,
  domain = cookieDomain,
  forceSameSiteSecure = false
) => {
  const now = new Date();
  now.setTime(now.getTime() + -999);
  document.cookie = `${key}=; expires=${now.toUTCString()}; path=/; domain=${domain}${
    forceSameSiteSecure ? "; SameSite=None; Secure" : ""
  }`;
};

type IReturn<T> = [T, (value: T) => void];

export const useCookie = <T>(
  key: string,
  defaultValue: T,
  valueToString: (value: T) => string,
  stringToValue: (value: string) => T,
  numberOfDaysTillExpire = 10
): IReturn<T> => {
  const getCookie = () => stringToValue(getCookieItem(key)) || defaultValue;
  const [cookie, setCookie] = useState(getCookie());

  const updateCookie = (value: T) => {
    setCookie(value);
    setCookieItem(key, valueToString(value), numberOfDaysTillExpire);
  };

  return [cookie, updateCookie];
};
