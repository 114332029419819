export enum SocialURLS {
  AFFILIATE = "https://pretzel.tapfiliate.com",
  AFFILIATE_LOGIN = "https://pretzel.tapfiliate.com/login",
  BRAND_PACK = "https://bit.ly/PretzelBrandPack",
  CANCEL_SUBSCRIPTION = "https://help.pretzel.rocks/cancel-pretzel-rocks",
  DOWNLOAD_MAC = "https://download.pretzel.rocks/PretzelDesktop.dmg",
  DOWNLOAD_WINDOWS = "https://download.pretzel.rocks/PretzelDesktop.exe",
  FACEBOOK = "https://www.facebook.com/PretzelRocks/",
  FAQS = "https://help.pretzel.rocks/pretzel",
  FEEDBACK = "https://feedback.pretzel.rocks/",
  HELP = "https://help.pretzel.rocks/",
  INSTAGRAM = "https://www.instagram.com/pretzelrocks/",
  PRETZEL_BLOG = "https://blog.pretzel.rocks/",
  TWITTER = "https://twitter.com/pretzel_rocks",
  ELGATO_STREAMDECK_STORE = "https://apps.elgato.com/plugins/rocks.pretzel.play",
  BECOME_A_PARTNER = "https://share.hsforms.com/1lyhg9tVCTwScGFIaVEayPg12n7k",

  PAYPAL_TIP = "https://streamelements.com/rab400/tip",
  DISCORD_INVITE = "https://discord.gg/GyskQ4s6p2",
  TWITCH = "https://www.twitch.tv/rab400",

  STATUS_PAGE = "https://retirementgg.instatus.com",
}
