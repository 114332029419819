import * as React from 'react';

function SvgTwitch(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 32 32" fill="currentColor" {...props}>
      <path d="M16 7.744v7.744h-2.592V7.744H16zm7.104 0v7.744h-2.592V7.744h2.592zm0 13.568l4.512-4.512V2.592H6.304v18.72h5.824v3.872L16 21.312h7.104zM30.208 0v18.08l-7.744 7.744H16.64l-3.872 3.904H8.896v-3.904H1.792V5.152L3.744 0h26.464z" />
    </svg>
  );
}

export default SvgTwitch;
