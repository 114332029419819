import { FC } from "react";
import { Link } from "./styled";
import { iconDictionary, IconName, IconProps } from "./types";

export const SocialIcon: FC<IconProps> = ({
  icon,
  href,
  target,
  color,
  hoverColor,
  size,
  children,
  link,
}) => {
  let Component = iconDictionary[IconName[icon] as keyof typeof iconDictionary];

  if (link) {
    return (
      <Link href={href} target={target ?? "_blank"} hoverColor={hoverColor}>
        {children}
        <Component
          fill={color ?? "currentColor"}
          width={size ? `${size}px` : "auto"}
        />
      </Link>
    );
  } else {
    return (
      <span>
        {children}
        <Component
          fill={color ?? "currentColor"}
          width={size ? `${size}px` : "auto"}
        />
      </span>
    );
  }
};
